import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardAccess from '../components/Sidebar/DashboardAccess';
import { BotData, Customer } from '../types';

interface SettingsPageProps {
    botData: BotData;
    customerData: Customer;
    handleLogout: () => void;
    handleBlur: () => void;
}

export const DashboardAccessPage: React.FC<SettingsPageProps> = ({ botData, handleLogout, customerData, handleBlur }) => {
    const navigate = useNavigate();


    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }

    return (
        <div >
            <DashboardAccess handleLogOut={handleLogout} handleBlur={handleBlur} app_id={botData.app_id} />
        </div>
    );
};