import React from 'react';
import axios from 'axios';
import { Users } from 'lucide-react';
import { API_URL } from '../../constants';

interface NoPremiumProps { }

const NoPremiumPage: React.FC<NoPremiumProps> = () => {
    const validateKey = async (key: string) => {
        try {
            const response = await axios.post(
                `${API_URL}/v1/validate/bot/invite`,
                { key },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );

            if (response.status === 200 && !response.data.data.error) {
                window.location.search = '';
                window.location.reload();
            }
        } catch (error) {
            console.error('Key validation failed:', error);
        }
    };

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const key = searchParams.get('key');
        const type = searchParams.get('type');

        if (key && type === "invite") {
            validateKey(key);
        }
    }, []);

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729] items-center justify-center px-4">
            <div className="bg-[#1a2234] rounded-xl overflow-hidden shadow-xl max-w-md w-full">
                <div className="p-8 space-y-6">
                    <div className="space-y-2 text-center">
                        <h2 className="text-2xl font-semibold text-slate-100">
                            Hey, {localStorage.getItem("name")}!
                        </h2>
                        <p className="text-slate-400">
                            You're currently missing out on premium features.
                        </p>
                    </div>

                    <a
                        href="https://discord.gg/ZTqv2QcZnn"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center gap-2 px-6 py-3 bg-[#5865F2] hover:bg-[#4752C4] text-white font-medium rounded-lg transition-colors"
                    >
                        <Users size={20} />
                        Join Discord Server
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NoPremiumPage;