import React from 'react';
import { ChevronDown } from 'lucide-react';
import { MonitorInput } from '../../../../types';

interface RegionSelectProps {
    newItem: MonitorInput;
    setNewItem: (item: MonitorInput) => void;
    availableRegions: string[];
}

const RegionSelect: React.FC<RegionSelectProps> = ({
    newItem,
    setNewItem,
    availableRegions
}) => (
    <div>
        <label className="block text-sm text-slate-400 mb-1">Region</label>
        <div className="relative">
            <select
                value={newItem.region}
                onChange={(e) => setNewItem({ ...newItem, region: e.target.value })}
                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none pr-8"
            >
                <option value="" disabled>Select Region</option>
                <option key="all" value="all">All Regions</option>
                {availableRegions.map((region) => (
                    <option
                        key={region}
                        value={region}
                        className="bg-[#0f1729] text-slate-100"
                    >
                        {region}
                    </option>
                ))}
            </select>
            <ChevronDown
                size={16}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
            />
        </div>
    </div>
);

export default RegionSelect;