import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MonitorTable from "./MonitorTable";

import ProxySettings from "./ProxySettings";
import KeywordSettings from "./KeywordSettings";
import { API_URL } from "../../../constants";
import { Monitor, MonitorProps, MonitorData } from "../../../types";
import { useLoading } from "../../../contexts/LoadingContext";


const Monitors: React.FC<MonitorProps> = ({ handleLogOut, developer, handleBlur, customerData }) => {

    const navigate = useNavigate();
    const { setIsLoading } = useLoading();

    const [monitorsPreview, setMonitorsPreview] = useState<MonitorData[]>([]);

    useEffect(() => {
        const getMonitors = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/v1/monitors`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || ""
                    }
                });

                if (response.status === 200) {
                    const data = response.data.data.monitors;
                    setMonitorsPreview(data);
                }
            } catch (error) {
                handleLogOut();
            }
            finally {
                setIsLoading(false);
            }
        }
        if (monitorsPreview.length === 0) {
            getMonitors()
        }

    }, [handleLogOut]);



    const handleEdit = (monitor: MonitorData) => {
        const monitorTemp: Monitor = {
            data: monitor,
            webhooks: [],
            inputs: []
        };
        navigate(`/monitors/${monitorTemp.data.monitor_uuid}`);
    };


    return (
        <div className="scrollable">
            {developer && (
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-1/2">
                        <ProxySettings handleBlur={handleBlur} />
                    </div>
                    <div className="w-full md:w-1/2">
                        <KeywordSettings handleBlur={handleBlur} />
                    </div>
                </div>

            )}
            {monitorsPreview && (
                <MonitorTable
                    monitorsPreview={monitorsPreview}
                    handleEdit={handleEdit}
                    developer={developer}
                />
            )}
        </div>
    );
};

export default Monitors;
