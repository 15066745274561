import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ArrowLeft, Save, Trash2, Edit2, XCircle, Plus, Search, X } from 'lucide-react';
import ReactDOM from 'react-dom';

import { CustomToastContainer } from '../../common/CustomToastContainer';
import { toast } from 'react-toastify';
import { API_URL } from '../../../constants';



interface KeywordList {
    id: number;
    name: string;
    keywords: string[];
}

interface KeywordSettingsProps {
    handleBlur: () => void;
}

const KeywordSettings: React.FC<KeywordSettingsProps> = ({ handleBlur }) => {
    const [keywords, setKeywords] = useState<KeywordList[]>([]);
    const [scrape, setScrape] = useState<boolean>(false);
    const [newKeywordList, setNewKeywordList] = useState<KeywordList>({ id: -1, name: "", keywords: [] });
    const [searchTerm, setSearchTerm] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [editingKeywordList, setEditingKeywordList] = useState<KeywordList | null>(null);

    useEffect(() => {
        setScrape(true);
    }, []);

    useEffect(() => {
        if (scrape) {
            fetchKeywordData();
        }
    }, [scrape]);

    // Fetch keyword data
    const fetchKeywordData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/keywords`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                }
            });
            if (response.status === 200) {
                setKeywords(response.data);
            }
        } catch (error) {
            console.error('Error fetching keyword data:', error);
        }
    };

    // Save a keyword list
    const saveKeywordList = async (keywordList: KeywordList) => {
        const toastId = toast.loading("Saving keyword list...");
        try {
            const response = await axios.put(`${API_URL}/v1/keywords`, {
                keywords: keywordList.keywords,
                keywordListID: keywordList.id,
                name: keywordList.name,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Keywords updated successfully');
                toast.update(toastId, { render: "Keyword list saved successfully", type: "success", isLoading: false, autoClose: 3000 });

                fetchKeywordData();
            }
        } catch (error) {
            console.error('Failed to update keywords', error);
            toast.update(toastId, { render: "Failed to save keyword list", type: "error", isLoading: false, autoClose: 3000 });

        }
    };

    // Delete a keyword list
    const deleteKeywordList = async (keywordListID: number) => {
        const toastId = toast.loading("Deleting keyword list...");
        try {
            const response = await axios.delete(`${API_URL}/v1/keywords`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                data: { keywordListID: keywordListID }
            });

            if (response.status === 200) {
                console.log('Keyword list deleted successfully');
                setKeywords(prev => prev.filter(k => k.id !== keywordListID));
                toast.update(toastId, { render: "Keyword list deleted successfully", type: "success", isLoading: false, autoClose: 3000 });

            }
        } catch (error) {
            console.error('Failed to delete keyword list', error);
            toast.update(toastId, { render: "Failed to delete keyword list", type: "error", isLoading: false, autoClose: 3000 });

        }
    };

    // Create a new keyword list
    const createNewKeywordList = async () => {
        const toastId = toast.loading("Creating new keyword list...");
        try {
            const response = await axios.post(`${API_URL}/v1/keywords`, {
                keywords: newKeywordList.keywords,
                name: newKeywordList.name,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Keyword list created successfully');
                setShowPopup(false);
                handleBlur();

                const updatedKeywordList = {
                    ...newKeywordList,
                    id: response.data.data.new_id
                };
                console.log(updatedKeywordList);

                setKeywords((prevKeywords) => [
                    ...prevKeywords,
                    updatedKeywordList
                ]);

                setNewKeywordList({ id: -1, name: "", keywords: [] });
                toast.update(toastId, { render: "New keyword list created successfully", type: "success", isLoading: false, autoClose: 3000 });

            }
        } catch (error) {
            console.error('Failed to create keyword list', error);
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewKeywordList((prevState) => ({
            ...prevState,
            name: event.target.value
        }));
    };

    const handleKeywordsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const keywordArray = event.target.value.split('\n');
        setNewKeywordList((prevState) => ({
            ...prevState,
            keywords: keywordArray
        }));
    };

    const openEditDialog = (keywordList: KeywordList) => {
        setEditingKeywordList(keywordList);
    };

    const filteredKeywordLists = keywords.filter(keyword =>
        keyword.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="flex flex-col w-full">
            <CustomToastContainer />
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Keyword Settings</h1>

                <div className="max-w-5xl mx-auto space-y-6">
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search keyword lists..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full sm:w-64 bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400" size={16} />
                                </div>

                                <button
                                    onClick={() => { setShowPopup(true); handleBlur(); }}
                                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    <Plus size={16} />
                                    <span>Create Keyword List</span>
                                </button>
                            </div>

                            <div className="relative overflow-x-auto">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr className="border-b border-slate-700/50">
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Name</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Keywords</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredKeywordLists.map((keywordList) => (
                                            <tr
                                                key={keywordList.id}
                                                className="border-b border-slate-700/50 last:border-b-0 hover:bg-slate-700/20 transition-colors"
                                            >
                                                <td className="py-3 px-4">
                                                    <span className="text-sm text-slate-100 font-medium">
                                                        {keywordList.name}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <span className="text-sm text-slate-300">
                                                        {keywordList.keywords.length} keywords
                                                    </span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <div className="flex items-center justify-center gap-2">
                                                        <button
                                                            onClick={() => { openEditDialog(keywordList); handleBlur() }}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Edit2 size={16} className="text-slate-400 group-hover:text-yellow-400" />
                                                        </button>
                                                        <button
                                                            onClick={() => deleteKeywordList(keywordList.id)}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Trash2 size={16} className="text-slate-400 group-hover:text-red-400" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {filteredKeywordLists.length === 0 && (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className="py-4 px-4 text-center text-sm text-slate-400"
                                                >
                                                    No keyword lists found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Create/Edit Modal */}
            {(showPopup || editingKeywordList) && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-6">
                                <h2 className="text-xl font-semibold text-slate-100">
                                    {editingKeywordList ? 'Edit Keyword List' : 'Create Keyword List'}
                                </h2>
                                <button
                                    onClick={() => {
                                        setShowPopup(false);
                                        handleBlur();
                                        setEditingKeywordList(null);
                                    }}
                                    className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
                                >
                                    <X size={16} className="text-slate-400" />
                                </button>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Name</label>
                                    <input
                                        type="text"
                                        value={editingKeywordList ? editingKeywordList.name : newKeywordList.name}
                                        onChange={editingKeywordList
                                            ? (e) => setEditingKeywordList({ ...editingKeywordList, name: e.target.value })
                                            : handleNameChange
                                        }
                                        placeholder="Enter keyword list name"
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Keywords</label>
                                    <textarea
                                        value={editingKeywordList
                                            ? editingKeywordList.keywords.join('\n')
                                            : newKeywordList.keywords.join('\n')
                                        }
                                        onChange={editingKeywordList
                                            ? (e) => setEditingKeywordList({
                                                ...editingKeywordList,
                                                keywords: e.target.value.split('\n')
                                            })
                                            : handleKeywordsChange
                                        }
                                        placeholder="Enter keywords (one per line)"
                                        className="w-full h-40 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 resize-none"
                                    />
                                </div>
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-3">
                                <button
                                    onClick={() => {
                                        setShowPopup(false);
                                        handleBlur();
                                        setEditingKeywordList(null);
                                    }}
                                    className="px-4 py-2 text-slate-300 hover:text-slate-100 text-sm transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        if (editingKeywordList) {
                                            saveKeywordList(editingKeywordList);
                                            setEditingKeywordList(null);
                                        } else {
                                            createNewKeywordList();
                                        }
                                        setShowPopup(false);
                                        handleBlur();
                                    }}
                                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    <Save size={16} />
                                    <span>{editingKeywordList ? 'Save Changes' : 'Create List'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default KeywordSettings;