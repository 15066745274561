import React from 'react';
import { useNavigate } from 'react-router-dom';
import BaseCommands from '../components/Sidebar/Command/Commands';
import { BotData, Customer } from '../types';

interface SettingsPageProps {
    botData: BotData;
    customerData: Customer;
    handleLogOut: () => void;
    handleBlur: () => void;
}

export const CommandsPage: React.FC<SettingsPageProps> = ({ botData, customerData, handleLogOut, handleBlur }) => {
    const navigate = useNavigate();

    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }

    return (
        <div>
            <BaseCommands handleBlur={handleBlur} handleLogOut={handleLogOut} app_id={botData.app_id} />
        </div>
    );
};