import React, { useRef } from 'react';
import { Calendar, CreditCard } from 'lucide-react';

interface Purchase {
    monitor: string[];
    ticket: string[];
    toolbox: string[];
}

interface CustomerData {
    footer_icon: string;
    hex_color: string;
    image: string;
    name: string;
    next_renewal: string;
    purchases: Purchase;
}

interface PaymentViewProps {
    customerData: CustomerData;
}

const PaymentView: React.FC<PaymentViewProps> = ({ customerData }) => {
    const toolboxRef = useRef<HTMLDivElement>(null);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleStripePayment = () => {
        console.log('Stripe payment button clicked');
        alert('Stripe integration not yet implemented');
    };

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729]">
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Payment Information</h1>

                <div className="max-w-5xl mx-auto space-y-6">
                    {/* Customer Info Card */}
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <div className="flex flex-col sm:flex-row items-center gap-6">
                                <div className="flex flex-col sm:flex-row items-center gap-6">
                                    <div className="relative">
                                        <div className="h-16 w-16 rounded-xl overflow-hidden ring-2 ring-slate-700/50">
                                            <img
                                                src={customerData.image}
                                                alt={customerData.name}
                                                className="h-full w-full object-cover"
                                            />
                                        </div>
                                    </div>

                                    <div className="space-y-1 text-center sm:text-left">
                                        <h2 className="text-xl font-semibold" style={{ color: customerData.hex_color }}>
                                            {customerData.name}
                                        </h2>
                                        <div className="flex items-center gap-2 text-slate-400">
                                            <Calendar size={16} />
                                            <span className="text-sm">
                                                Renews {formatDate(customerData.next_renewal)}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-1 flex justify-end">
                                    <button
                                        onClick={handleStripePayment}
                                        className="flex items-center gap-2 px-4 py-2 bg-[#635bff] hover:bg-[#4f46e5] text-white text-sm rounded-lg transition-colors"
                                    >
                                        <CreditCard size={16} />
                                        <span>Pay with Stripe</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Purchases Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        {/* Monitors Card */}
                        {customerData.purchases.monitor && (
                            <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                                <div className="p-4 sm:p-6">
                                    <div className="flex items-center gap-2 mb-4">
                                        <h3 className="text-lg font-medium text-slate-100">Purchased Monitors</h3>
                                        <span className="px-2 py-0.5 text-xs rounded-full bg-blue-500/10 text-blue-400 border border-blue-500/20">
                                            {customerData.purchases.monitor.length}
                                        </span>
                                    </div>

                                    <div className="space-y-2">
                                        {customerData.purchases.monitor.map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-2 p-2 rounded-lg bg-slate-700/20 border border-slate-700/50"
                                            >
                                                <div className="h-2 w-2 rounded-full bg-blue-500/80" />
                                                <span className="text-sm text-slate-300 capitalize">{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}


                        {/* Toolbox Card */}
                        <div
                            ref={toolboxRef}
                            className="bg-[#1a2234] rounded-xl overflow-hidden"
                        >
                            <div className="p-4 sm:p-6">
                                <div className="flex items-center gap-2 mb-4">
                                    <h3 className="text-lg font-medium text-slate-100">Purchased Toolbox</h3>
                                    <span className="px-2 py-0.5 text-xs rounded-full bg-emerald-500/10 text-emerald-400 border border-emerald-500/20">
                                        {customerData.purchases.toolbox?.length || 0}
                                    </span>
                                </div>

                                <div className="space-y-2">
                                    {customerData.purchases.toolbox?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center gap-2 p-2 rounded-lg bg-slate-700/20 border border-slate-700/50"
                                        >
                                            <div className="h-2 w-2 rounded-full bg-emerald-500/80" />
                                            <span className="text-sm text-slate-300 capitalize">{item}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentView;