import React from 'react';
import { X, Save, ChevronDown } from 'lucide-react';
import { Customer, MonitorInput } from '../../../../types';
import ReactDOM from 'react-dom';

interface EditDialogProps {
    editingItem: MonitorInput;
    setEditingItem: (item: MonitorInput) => void;
    handleBlur: () => void;
    updateItem: (item: MonitorInput) => void;
    setIsEditDialogOpen: (isOpen: boolean) => void;
    isEditDialogOpen: boolean;
    availableRegions: string[];
    handleSizeRangeChange: (e: React.ChangeEvent<HTMLInputElement>, type: 'from' | 'to', item: MonitorInput) => void;
    customerData: Customer | null
}

const EditDialog: React.FC<EditDialogProps> = ({
    editingItem,
    setEditingItem,
    updateItem,
    setIsEditDialogOpen,
    isEditDialogOpen,
    availableRegions,
    handleSizeRangeChange,
    customerData,
    handleBlur
}) => {


    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                <div className="p-6">
                    <div className="flex items-center justify-between mb-6">
                        <h2 className="text-xl font-semibold text-slate-100">Edit Item</h2>
                        <button
                            onClick={() => { setIsEditDialogOpen(false); handleBlur() }}
                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
                        >
                            <X size={16} className="text-slate-400" />
                        </button>
                    </div>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm text-slate-400 mb-1">Link/Product ID</label>
                            <input
                                value={editingItem.link}
                                onChange={(e) => setEditingItem({ ...editingItem, link: e.target.value })}
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm text-slate-400 mb-1">
                                Size Range: <span className="text-slate-300">{editingItem.sizeRange}</span>
                            </label>
                            <div className="flex items-center gap-4">
                                <span className="text-xs text-slate-400">36</span>
                                <div className="flex-1 flex gap-2">
                                    <input
                                        type="range"
                                        min="36"
                                        max="50"
                                        value={editingItem.sizeRange.split('-')[0]}
                                        onChange={(e) => handleSizeRangeChange(e, 'from', editingItem)}
                                        className="flex-1 accent-blue-500"
                                    />
                                    <input
                                        type="range"
                                        min="36"
                                        max="50"
                                        value={editingItem.sizeRange.split('-')[1]}
                                        onChange={(e) => handleSizeRangeChange(e, 'to', editingItem)}
                                        className="flex-1 accent-blue-500"
                                    />
                                </div>
                                <span className="text-xs text-slate-400">50</span>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm text-slate-400 mb-1">Special Webhook</label>
                            <input
                                value={editingItem.specialWebhook}
                                onChange={(e) => setEditingItem({ ...editingItem, specialWebhook: e.target.value })}
                                placeholder="Enter webhook URL"
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm text-slate-400 mb-1">Note</label>
                            <input
                                value={editingItem.note}
                                onChange={(e) => setEditingItem({ ...editingItem, note: e.target.value })}
                                placeholder="Add a note"
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                            />
                        </div>

                        <div>
                            <label className="block text-sm text-slate-400 mb-1">Price Limit</label>
                            <input
                                type="number"
                                value={editingItem.priceLimit || ''}
                                onChange={(e) => setEditingItem({
                                    ...editingItem,
                                    priceLimit: e.target.value ? Number(e.target.value) : undefined
                                })}
                                placeholder="Set price limit"
                                className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                            />
                        </div>

                        {availableRegions.length > 1 && (
                            <div>
                                <label className="block text-sm text-slate-400 mb-1">Region</label>
                                <div className="relative">
                                    <select
                                        value={editingItem.region}
                                        onChange={(e) => setEditingItem({ ...editingItem, region: e.target.value })}
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none"
                                    >
                                        <option value="" disabled>Select Region</option>
                                        <option value="all">All</option>
                                        {availableRegions.map((region) => (
                                            <option key={region} value={region}>{region}</option>
                                        ))}
                                    </select>
                                    <ChevronDown
                                        size={16}
                                        className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
                                    />
                                </div>
                            </div>
                        )}

                        {customerData?.purchases?.monitor?.includes("private inputs") && (
                            <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="privateCheckbox"
                                    checked={editingItem.private || false}
                                    onChange={(e) => setEditingItem({ ...editingItem, private: e.target.checked })}
                                    className="w-4 h-4 rounded border-slate-700/50 bg-[#0f1729] text-blue-500 focus:ring-blue-500 focus:ring-offset-0"
                                />
                                <label htmlFor="privateCheckbox" className="text-sm text-slate-300">
                                    Private Input
                                </label>
                            </div>
                        )}
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-3">
                        <button
                            onClick={() => { setIsEditDialogOpen(false); handleBlur() }}
                            className="px-4 py-2 text-slate-300 hover:text-slate-100 text-sm transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                updateItem(editingItem);
                                setIsEditDialogOpen(false);
                                handleBlur()
                            }}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                        >
                            <Save size={16} />
                            <span>Save Changes</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default EditDialog;