import React, { useState } from 'react';
import { ChevronDown, ChevronUp, CreditCard, Package } from 'lucide-react';
import { Product } from '../../../types';

interface ProductSelectorProps {
    products: Product[];
    selectedProducts: Product[];
    onProductChange: (products: Product[]) => void;
}

export const ProductSelector: React.FC<ProductSelectorProps> = ({ products, selectedProducts, onProductChange }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const monitorProducts = products.filter(p => p.type === 'monitor');
    const toolboxProducts = products.filter(p => p.type === 'toolbox');

    return (
        <div className="relative">
            <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="flex items-center gap-2 px-4 py-2 bg-slate-700/50 hover:bg-slate-700 text-slate-200 rounded-lg transition-colors"
            >
                Manage Products
                {showDropdown ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </button>

            {showDropdown && (
                <div className="absolute bottom-full right-0 mb-2 w-72 rounded-lg bg-slate-800 shadow-xl ring-1 ring-slate-700">
                    <div className="divide-y divide-slate-700/50">
                        {/* Monitors Section */}
                        <div className="p-3">
                            <div className="flex items-center gap-2 text-slate-300 mb-2 px-2">
                                <Package size={18} />
                                <span className="font-medium">Monitors</span>
                            </div>
                            <div className="space-y-1 max-h-40 overflow-y-auto">
                                {monitorProducts.map((product) => (
                                    <label
                                        key={product.name}
                                        className="flex items-center gap-3 p-2 hover:bg-slate-700/50 rounded-md cursor-pointer group"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedProducts.some(p => p.name === product.name)}
                                            onChange={() => {
                                                if (selectedProducts.some(p => p.name === product.name)) {
                                                    onProductChange(selectedProducts.filter(p => p.name !== product.name));
                                                } else {
                                                    onProductChange([...selectedProducts, product]);
                                                }
                                            }}
                                            className="h-4 w-4 rounded border-slate-600 text-blue-500 focus:ring-blue-500"
                                        />
                                        <div className="flex-1">
                                            <span className="text-slate-300 group-hover:text-slate-100 transition-colors">
                                                {product.name}
                                            </span>
                                            <div className="text-xs text-slate-400">monitor</div>
                                        </div>
                                        <div className="h-2 w-2 rounded-full bg-blue-500/80" />
                                    </label>
                                ))}
                            </div>
                        </div>

                        {/* Toolbox Section */}
                        <div className="p-3">
                            <div className="flex items-center gap-2 text-slate-300 mb-2 px-2">
                                <CreditCard size={18} />
                                <span className="font-medium">Toolbox</span>
                            </div>
                            <div className="space-y-1 max-h-40 overflow-y-auto">
                                {toolboxProducts.map((product) => (
                                    <label
                                        key={product.name}
                                        className="flex items-center gap-3 p-2 hover:bg-slate-700/50 rounded-md cursor-pointer group"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedProducts.some(p => p.name === product.name)}
                                            onChange={() => {
                                                if (selectedProducts.some(p => p.name === product.name)) {
                                                    onProductChange(selectedProducts.filter(p => p.name !== product.name));
                                                } else {
                                                    onProductChange([...selectedProducts, product]);
                                                }
                                            }}
                                            className="h-4 w-4 rounded border-slate-600 text-blue-500 focus:ring-blue-500"
                                        />
                                        <div className="flex-1">
                                            <span className="text-slate-300 group-hover:text-slate-100 transition-colors">
                                                {product.name}
                                            </span>
                                            <div className="text-xs text-slate-400">toolbox</div>
                                        </div>
                                        <div className="h-2 w-2 rounded-full bg-emerald-500/80" />
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};