import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CustomerCard } from './CustomerCard';
import { CustomerData, Product } from '../../../types';
import { API_URL } from '../../../constants';
import { CustomToastContainer } from '../../common/CustomToastContainer';
import UpcomingRenewals from './UpcomingRenewals';
import { useLoading } from '../../../contexts/LoadingContext';

interface CustomerListProps {
}

export const CustomerList: React.FC<CustomerListProps> = () => {
    const [customers, setCustomers] = useState<CustomerData[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [isInitialized, setIsInitialized] = useState(false);

    const { setIsLoading } = useLoading();



    const getCustomers = async () => {
        try {
            const response = await axios.get<CustomerData[]>(`${API_URL}/v1/customers`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || ""
                }
            });
            if (response.status === 200) {
                setCustomers(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getProducts = async () => {
        try {
            const response = await axios.get<Product[]>(`${API_URL}/v1/products`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || ""
                }
            });
            if (response.status === 200) {
                setProducts(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }



    useEffect(() => {
        const fetchData = async () => {
            if (isInitialized) return;

            setIsLoading(true);
            try {
                await Promise.all([
                    getCustomers(),
                    getProducts()
                ]);
                setIsInitialized(true);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isInitialized]);

    const updateCustomer = async (customer: CustomerData) => {
        const toastId = toast.loading("Saving customer...");
        try {
            const response = await axios.put(`${API_URL}/v1/customers`, {
                customer: customer,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                toast.update(toastId, { render: "Customer updated successfully", type: "success", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.error('Failed to update customer', error);
            toast.update(toastId, { render: "Failed to save customer", type: "error", isLoading: false, autoClose: 3000 });
        }
    }

    const handleCustomerUpdate = (updatedCustomer: CustomerData) => {
        setCustomers(prevCustomers =>
            prevCustomers.map(c => c.customer_id === updatedCustomer.customer_id ? updatedCustomer : c)
        );
        updateCustomer(updatedCustomer);
    };

    const upcomingRenewals = customers
        .filter(customer => {
            const daysUntilRenewal = Math.ceil(
                (new Date(customer.next_renewal).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
            );
            return daysUntilRenewal <= 7;
        })
        .sort((a, b) => new Date(a.next_renewal).getTime() - new Date(b.next_renewal).getTime());

    return (
        <div className="flex flex-col w-full scrollable">
            <CustomToastContainer />

            {/* Static Header Content */}
            <div className="w-full p-8">
                <h1 className="text-4xl text-white font-bold mb-8">Customer Dashboard</h1>

                {upcomingRenewals.length > 0 && (
                    <div className="mb-8">
                        <UpcomingRenewals customers={upcomingRenewals} />
                    </div>
                )}

                <div className="relative mb-8">
                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                    <input
                        type="text"
                        placeholder="Search customers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full rounded-lg bg-gray-800/50 py-3 pl-10 pr-4 text-gray-100 ring-1 ring-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
            </div>

            {/* Scrollable Content */}
            <div className="w-full px-8 pb-8">
                <div className="max-w-7xl mx-auto space-y-6">
                    {customers
                        .filter(customer =>
                            customer.name.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                        .map(customer => (
                            <CustomerCard
                                key={customer.customer_id}
                                customer={customer}
                                onUpdate={handleCustomerUpdate}
                                allProducts={products}
                            />
                        ))}
                    {/* Bottom spacing */}
                    <div className="h-8" />
                </div>
            </div>
        </div>
    );
};