import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Edit2, Search, RefreshCw } from 'lucide-react';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import { API_URL } from '../../../constants';
import CommandPopUp from './CommandPopUp';
import { useLoading } from '../../../contexts/LoadingContext';

// Types
interface Permission {
    channels?: { id: string; permission: boolean; }[];
    members?: { id: string; permission: boolean; }[];
    roles?: { id: string; permission: boolean; color: number }[];
}

interface Commands {
    id: string;
    name: string;
    permissions: Permission;
}

interface Roles {
    id: string;
    name: string;
    color: number;
}

interface Members {
    id: string;
    name: string;
    avatar: string;
}

interface Channels {
    id: string;
    name: string;
}

interface ServerState {
    icon: string;
    id: string;
    name: string;
}

interface BaseCommandsProps {
    handleBlur: () => void;
    handleLogOut: () => void;
    app_id: string;
}

// Select components
const SelectOption = ({ data, ...props }: any) => (
    <div className="flex items-center gap-2 p-2 hover:bg-slate-700/50 cursor-pointer">
        <img
            src={data.icon}
            alt={data.name}
            className="w-5 h-5 rounded-full"
        />
        <span className="text-slate-100">{data.name}</span>
    </div>
);

const SelectValue = ({ data }: any) => (
    <div className="flex items-center gap-2 !h-8 !p-0 absolute top-1/2 -translate-y-1/2">
        <img
            src={data.icon}
            alt={data.name}
            className="w-5 h-5 rounded-full flex-shrink-0"
        />
        <span className="text-slate-100 text-sm leading-none">{data.name}</span>
    </div>
);

const BaseCommands: React.FC<BaseCommandsProps> = ({
    handleBlur,
    handleLogOut,
    app_id
}) => {
    // State
    const [commands, setCommands] = useState<Commands[]>([]);
    const [roles, setRoles] = useState<Roles[]>([]);
    const [members, setMembers] = useState<Members[]>([]);
    const [channels, setChannels] = useState<Channels[]>([]);
    const [currentCommand, setCurrentCommand] = useState<Commands | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [guilds, setGuilds] = useState<ServerState[]>([]);
    const [selectedServer, setSelectedServer] = useState<ServerState | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [serverId, setServerId] = useState('');

    const guildsFetchedRef = useRef(false);

    const { setIsLoading } = useLoading();

    // Fetch initial data
    useEffect(() => {
        const fetchGuilds = async () => {
            if (guildsFetchedRef.current) return;
            guildsFetchedRef.current = true;

            try {
                setIsLoading(true);
                const response = await axios.get(`${API_URL}/v1/user/info/bot/guilds`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });

                if (response.status === 200) {
                    const fetchedGuilds = response.data.data.bots[0].guilds;
                    setGuilds(fetchedGuilds);
                    if (fetchedGuilds.length > 0) {
                        setSelectedServer(fetchedGuilds[0]);
                    }
                }
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.status === 429) {
                    toast.error('Too many requests. Please try again later.');
                } else {
                    toast.error('Failed to fetch servers. Please try again.');
                }
                handleLogOut();
            } finally {
                setIsLoading(false);
            }
        };
        fetchGuilds();
    }, [handleLogOut]);

    // Fetch server data when selected server changes
    useEffect(() => {
        if (selectedServer) {
            setIsLoading(true);
            try {
                Promise.all([
                    fetchCommands(),
                    fetchRoles(),
                    fetchMembers(),
                    fetchChannels(),
                    checkAuth()
                ]);
            }
            finally {
                setIsLoading(false);
            }
        }
    }, [selectedServer]);

    // Data fetching functions
    const fetchCommands = async () => {
        if (!selectedServer) return;

        try {
            const response = await axios.get(
                `${API_URL}/v1/user/info/bot/commands?guild_id=${selectedServer.id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );
            setCommands(response.data.data.bots[0].commands);
            setServerId(selectedServer.id);
        } catch (error) {
            toast.error('Failed to fetch commands');
        }
    };

    const fetchRoles = async () => {
        if (!selectedServer) return;

        try {
            const response = await axios.get(
                `${API_URL}/v1/user/info/bot/guilds/${selectedServer.id}/roles`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );
            setRoles(response.data.data.bots[0].roles);
        } catch (error) {
            toast.error('Failed to fetch roles');
        }
    };

    const fetchMembers = async () => {
        if (!selectedServer) return;

        try {
            const response = await axios.get(
                `${API_URL}/v1/user/info/bot/guilds/${selectedServer.id}/members`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );
            setMembers(response.data.data.bots[0].members);
        } catch (error) {
            toast.error('Failed to fetch members');
        }
    };

    const fetchChannels = async () => {
        if (!selectedServer) return;

        try {
            const response = await axios.get(
                `${API_URL}/v1/user/info/bot/guilds/${selectedServer.id}/channels`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                }
            );
            setChannels(response.data.data.bots[0].channels);
        } catch (error) {
            toast.error('Failed to fetch channels');
        }
    };

    const checkAuth = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/user/oauth/check`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });

            if (response.status === 206) {
                window.location.replace(
                    `https://discord.com/api/oauth2/authorize?client_id=${app_id}&redirect_uri=https%3A%2F%2Fdashboard.solvextools.com%2Fcallback&response_type=code&scope=identify%20guilds%20applications.commands.permissions.update&state=${app_id}`
                );
            }
        } catch (error) {
            toast.error('Authentication failed');
            handleLogOut();
        }
    };

    // Event handlers
    const handleServerChange = (option: ServerState | null) => {
        setSelectedServer(option);
    };

    const showEditor = (command: Commands) => {
        setCurrentCommand(command);
        setShowPopup(true);
        handleBlur();
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        handleBlur();
    };

    const handleShowSuccess = () => {
        toast.success('Command permissions updated successfully');
        fetchCommands();
    };

    const hasCustomPermissions = (command: Commands): boolean => {
        return !!(
            (command.permissions.channels?.length ?? 0) > 0 ||
            (command.permissions.roles?.length ?? 0) > 0 ||
            (command.permissions.members?.length ?? 0) > 0
        );
    };

    const filteredCommands = commands.filter(command =>
        command.name.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729] scrollable">
            <div className="w-full px-4 sm:px-8 py-6">
                <div className="max-w-5xl mx-auto space-y-6">
                    <h2 className="text-2xl font-semibold text-slate-100">
                        Command Permissions
                    </h2>

                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <div className="flex flex-col sm:flex-row gap-4 mb-6">
                                <Select
                                    value={selectedServer}
                                    options={guilds}
                                    onChange={handleServerChange}
                                    getOptionLabel={(option: ServerState) => option.name}
                                    getOptionValue={(option: ServerState) => option.id}
                                    placeholder="Select Server"
                                    className="flex-1 sm:max-w-xs"
                                    classNamePrefix="select"
                                    isSearchable={false}
                                    components={{
                                        Option: SelectOption,
                                        SingleValue: SelectValue
                                    }}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            background: '#0f1729',
                                            borderColor: 'rgb(51 65 85 / 0.5)',
                                            padding: 0,
                                            '&:hover': {
                                                borderColor: 'rgb(71 85 105 / 0.5)'
                                            }
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            background: '#0f1729',
                                            border: '1px solid rgb(51 65 85 / 0.5)'
                                        }),
                                        option: (base) => ({
                                            ...base,
                                            background: 'transparent'
                                        })
                                    }}
                                />

                                <div className="relative flex-1 sm:max-w-xs">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search commands..."
                                        className="w-full bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <Search
                                        className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400"
                                        size={16}
                                    />
                                </div>
                            </div>

                            <div className="space-y-2">
                                {filteredCommands.map((command) => (
                                    <div
                                        key={command.id}
                                        className="flex items-center justify-between p-3 rounded-lg bg-[#0f1729] border border-slate-700/50 hover:border-slate-600/50 transition-colors"
                                    >
                                        <div className="flex items-center gap-3">
                                            <span className="text-sm text-slate-100 font-medium">
                                                {command.name}
                                            </span>
                                            {hasCustomPermissions(command) && (
                                                <div
                                                    className="p-1 rounded-md bg-blue-500/10 border border-blue-500/20"
                                                    title="Custom permissions applied"
                                                >
                                                    <RefreshCw size={12} className="text-blue-400" />
                                                </div>
                                            )}
                                        </div>

                                        <button
                                            onClick={() => showEditor(command)}
                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                        >
                                            <Edit2
                                                size={16}
                                                className="text-slate-400 group-hover:text-blue-400"
                                            />
                                        </button>
                                    </div>
                                ))}

                                {filteredCommands.length === 0 && (
                                    <div className="py-12 text-center">
                                        <p className="text-sm text-slate-400">
                                            No commands found
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && currentCommand && ReactDOM.createPortal(
                <div>
                    <CommandPopUp
                        current_command={currentCommand}
                        handle_close={handleClosePopup}
                        roles={roles}
                        members={members}
                        channels={channels}
                        server_id={serverId}
                        app_id={app_id}
                        handle_show_success={handleShowSuccess}
                    />
                </div>
                ,
                document.body
            )}
        </div>
    );
};

export default BaseCommands;