import React, { useState } from 'react';
import { Calendar, Package, Plus, CreditCard, ChevronDown, ChevronUp, Edit2 } from 'lucide-react';
import { EditableField } from './EditableField';
import { ProductSelector } from './ProductSelector';
import { CustomerData, Product } from '../../../types';

export interface CustomerCardProps {
    customer: CustomerData;
    onUpdate: (customer: CustomerData) => void;
    allProducts: Product[];
}

export const CustomerCard: React.FC<CustomerCardProps> = ({ customer, onUpdate, allProducts }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [editedDate, setEditedDate] = useState(customer.next_renewal);

    const formatDate = (dateString: string): string => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <div className="bg-slate-800/50 border border-slate-700/50 rounded-xl shadow-lg transition-all duration-200 hover:border-slate-600/50">
            <div className="p-6">
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6">
                    {/* Customer Info */}
                    {/* Customer Info */}
                    <div className="flex items-center gap-6">
                        <div className="relative">
                            <div className="h-16 w-16 rounded-xl overflow-hidden ring-2 ring-slate-700/50">
                                <img
                                    src={customer.image_url}
                                    alt={customer.name}
                                    className="h-full w-full object-cover"
                                />
                            </div>
                            {(() => {
                                const now = new Date();
                                const renewalDate = new Date(customer.next_renewal);
                                const isExpired = renewalDate < now;

                                return (
                                    <div className="absolute -bottom-1 -right-1 flex items-center">
                                        <div className={`h-4 w-4 rounded-full ring-2 ring-slate-900 ${isExpired ? 'bg-red-500' : 'bg-emerald-500'
                                            }`} />
                                        <div className={`absolute transform scale-0 group-hover:scale-100 transition-transform -translate-y-full -translate-x-1/2 bottom-5 px-2 py-1 rounded bg-slate-800 text-xs whitespace-nowrap ${isExpired ? 'text-red-200' : 'text-emerald-200'
                                            }`}>
                                            {isExpired ? 'Renewal Overdue' : 'Active Subscription'}
                                        </div>
                                    </div>
                                );
                            })()}
                        </div>

                        <div className="space-y-1">
                            <h3 className="text-xl font-semibold text-slate-100">{customer.name}</h3>
                            <div className="flex items-center gap-2">
                                <p className="text-sm text-slate-400">ID: {customer.customer_id}</p>
                                {(() => {
                                    const now = new Date();
                                    const renewalDate = new Date(customer.next_renewal);
                                    const isExpired = renewalDate < now;
                                    const daysUntilRenewal = Math.ceil((renewalDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));

                                    if (isExpired) {
                                        return (
                                            <span className="text-xs px-2 py-0.5 rounded-full bg-red-500/10 text-red-400 border border-red-500/20">
                                                Expired {Math.abs(daysUntilRenewal)} days ago
                                            </span>
                                        );
                                    } else if (daysUntilRenewal <= 30) {
                                        return (
                                            <span className="text-xs px-2 py-0.5 rounded-full bg-yellow-500/10 text-yellow-400 border border-yellow-500/20">
                                                Renews in {daysUntilRenewal} days
                                            </span>
                                        );
                                    }

                                    return (
                                        <span className="text-xs px-2 py-0.5 rounded-full bg-emerald-500/10 text-emerald-400 border border-emerald-500/20">
                                            Active
                                        </span>
                                    );
                                })()}
                            </div>
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="flex items-center gap-3 self-end lg:self-auto">
                        <button
                            onClick={() => {
                                const newDate = new Date(customer.next_renewal);
                                newDate.setMonth(newDate.getMonth() + 1);
                                onUpdate({ ...customer, next_renewal: newDate.toISOString() });
                            }}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors"
                        >
                            <Plus size={18} />
                            <span className="font-medium">Extend 1 Month</span>
                        </button>

                        <button
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
                            aria-expanded={isExpanded}
                        >
                            {isExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                        </button>
                    </div>
                </div>

                {/* Expanded Content */}
                {isExpanded && (
                    <div className="mt-6 pt-6 border-t border-slate-700/50">
                        <div className="grid lg:grid-cols-3 gap-8">
                            {/* Renewal Info */}
                            <div>
                                <div className="flex items-center gap-3 text-slate-300">
                                    <Calendar className="text-slate-400" size={20} />
                                    <div className="flex-1">
                                        <span className="text-sm text-slate-400 block">Next Renewal</span>
                                        {isEditingDate ? (
                                            <input
                                                type="date"
                                                value={editedDate.split('T')[0]}
                                                onChange={(e) => setEditedDate(e.target.value)}
                                                onBlur={() => {
                                                    onUpdate({ ...customer, next_renewal: new Date(editedDate).toISOString() });
                                                    setIsEditingDate(false);
                                                }}
                                                className="mt-1 w-full rounded-md bg-slate-700/50 px-3 py-1.5 text-slate-100 ring-1 ring-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                autoFocus
                                            />
                                        ) : (
                                            <div className="group relative inline-flex items-center gap-2 mt-1">
                                                <span className="font-medium">{formatDate(customer.next_renewal)}</span>
                                                <button
                                                    onClick={() => setIsEditingDate(true)}
                                                    className="opacity-0 group-hover:opacity-100 transition-opacity text-blue-400 hover:text-blue-300"
                                                >
                                                    <Edit2 size={14} />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Product Sections */}
                            <div>
                                <h4 className="flex items-center gap-2 mb-4 font-medium text-slate-200">
                                    <Package size={20} />
                                    Monitors
                                </h4>
                                <div className="space-y-2">
                                    {customer.products
                                        .filter(p => p.type === 'monitor')
                                        .map(product => (
                                            <div key={product.name} className="flex items-center gap-2">
                                                <div className="h-2 w-2 rounded-full bg-blue-500/80" />
                                                <span className="text-slate-300">{product.name}</span>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div>
                                <h4 className="flex items-center gap-2 mb-4 font-medium text-slate-200">
                                    <CreditCard size={20} />
                                    Toolbox
                                </h4>
                                <div className="space-y-2">
                                    {customer.products
                                        .filter(p => p.type === 'toolbox')
                                        .map(product => (
                                            <div key={product.name} className="flex items-center gap-2">
                                                <div className="h-2 w-2 rounded-full bg-emerald-500/80" />
                                                <span className="text-slate-300">{product.name}</span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>

                        {/* Product Selector */}
                        <div className="mt-6 flex justify-end">
                            <ProductSelector
                                products={allProducts}
                                selectedProducts={customer.products}
                                onProductChange={(products) => onUpdate({ ...customer, products })}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};