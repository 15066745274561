
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Save, Trash2, Edit2, Plus, Search, X } from 'lucide-react';
import ReactDOM from 'react-dom';
import { CustomToastContainer } from '../../common/CustomToastContainer';
import { toast } from 'react-toastify';
import { API_URL } from '../../../constants';



interface ProxyList {
    id: number;
    name: string;
    proxies: string;
}

interface ProxySettingsProps {
    handleBlur: () => void;
}

const ProxySettings: React.FC<ProxySettingsProps> = ({ handleBlur }) => {
    const [proxyLists, setProxyLists] = useState<ProxyList[]>([]);
    const [scrape, setScrape] = useState<boolean>(false);
    const [newProxyList, setNewProxyList] = useState<ProxyList>({ id: -1, name: "", proxies: "" });
    const [searchTerm, setSearchTerm] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [editingProxy, setEditingProxy] = useState<ProxyList | null>(null);

    useEffect(() => {
        setScrape(true);
    }, []);

    useEffect(() => {
        if (scrape) {
            fetchProxyData();
        }
    }, [scrape]);

    const fetchProxyData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/proxies`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                }
            });
            if (response.status === 200) {
                setProxyLists(response.data);
            }
        } catch (error) {
            console.error('Error fetching proxy data:', error);
        }
    };

    // Save a proxy list
    const saveProxyList = async (proxyList: ProxyList) => {
        const toastId = toast.loading("Saving proxy list...");
        try {
            const response = await axios.put(`${API_URL}/v1/proxies`, {
                proxyListData: proxyList.proxies,
                proxyListID: proxyList.id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Proxies updated successfully');
                toast.update(toastId, { render: "Proxy list saved successfully", type: "success", isLoading: false, autoClose: 3000 });
                fetchProxyData();
            }
        } catch (error) {
            console.error('Failed to update proxies', error);
            toast.update(toastId, { render: "Failed to save proxy list", type: "error", isLoading: false, autoClose: 3000 });
        }
    };

    // Delete a proxy list
    const deleteProxyList = async (proxyListID: number) => {
        const toastId = toast.loading("Deleting proxy list...");
        try {
            const response = await axios.delete(`${API_URL}/v1/proxies`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                data: { proxyListID: proxyListID }
            });

            if (response.status === 200) {
                console.log('Proxy list deleted successfully');
                setProxyLists(prev => prev.filter(p => p.id !== proxyListID));
                toast.update(toastId, { render: "Proxy list deleted successfully", type: "success", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.error('Failed to delete proxy list', error);
            toast.update(toastId, { render: "Failed to delete proxy list", type: "error", isLoading: false, autoClose: 3000 });
        }
    };

    // Create a new proxy list
    const createNewProxyList = async () => {
        if (newProxyList.name) {


            const toastId = toast.loading("Creating new proxy list...");
            try {
                const response = await axios.post(`${API_URL}/v1/proxies`, {
                    proxyListData: newProxyList.proxies,
                    proxyListName: newProxyList.name,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                });

                if (response.status === 200) {
                    console.log('ProxyList created successfully');
                    setShowPopup(false);
                    handleBlur()

                    const updatedProxyList = {
                        ...newProxyList,
                        id: response.data.data.new_id
                    };
                    console.log(updatedProxyList);

                    setProxyLists((prevProxies) => [
                        ...prevProxies,
                        updatedProxyList
                    ]);

                    setNewProxyList({ id: -1, name: "", proxies: "" });
                    toast.update(toastId, { render: "New proxy list created successfully", type: "success", isLoading: false, autoClose: 3000 });
                }
            } catch (error) {
                console.error('Failed to create proxyList', error);
                toast.update(toastId, { render: "Failed to create new proxy list", type: "error", isLoading: false, autoClose: 3000 });
            }
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewProxyList((prevState) => ({
            ...prevState,
            name: event.target.value
        }));
    };

    const handleProxiesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewProxyList((prevState) => ({
            ...prevState,
            proxies: event.target.value
        }));
    };


    const openEditDialog = (proxy: ProxyList) => {
        setEditingProxy(proxy);
    };

    const filteredProxyLists = proxyLists.filter(proxy =>
        proxy.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="flex flex-col w-full">
            <CustomToastContainer />
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Proxy Settings</h1>

                <div className="max-w-5xl mx-auto space-y-6">
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search proxy lists..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full sm:w-64 bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400" size={16} />
                                </div>

                                <button
                                    onClick={() => { setShowPopup(true); handleBlur() }}
                                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    <Plus size={16} />
                                    <span>Create Proxy List</span>
                                </button>
                            </div>

                            <div className="relative overflow-x-auto">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr className="border-b border-slate-700/50">
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Name</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Proxies</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredProxyLists.map((proxyList) => (
                                            <tr
                                                key={proxyList.id}
                                                className="border-b border-slate-700/50 last:border-b-0 hover:bg-slate-700/20 transition-colors"
                                            >
                                                <td className="py-3 px-4">
                                                    <span className="text-sm text-slate-100 font-medium">
                                                        {proxyList.name}
                                                    </span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <span className="text-sm text-slate-300">
                                                        {proxyList.proxies.split('\n').length} proxies
                                                    </span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <div className="flex items-center justify-center gap-2">
                                                        <button
                                                            onClick={() => { openEditDialog(proxyList); handleBlur() }}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Edit2 size={16} className="text-slate-400 group-hover:text-yellow-400" />
                                                        </button>
                                                        <button
                                                            onClick={() => deleteProxyList(proxyList.id)}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Trash2 size={16} className="text-slate-400 group-hover:text-red-400" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {filteredProxyLists.length === 0 && (
                                            <tr>
                                                <td
                                                    colSpan={3}
                                                    className="py-4 px-4 text-center text-sm text-slate-400"
                                                >
                                                    No proxy lists found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Create/Edit Modal */}
            {(showPopup || editingProxy) && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-6">
                                <h2 className="text-xl font-semibold text-slate-100">
                                    {editingProxy ? 'Edit Proxy List' : 'Create Proxy List'}
                                </h2>
                                <button
                                    onClick={() => {
                                        setShowPopup(false);
                                        handleBlur()
                                        setEditingProxy(null);
                                    }}
                                    className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
                                >
                                    <X size={16} className="text-slate-400" />
                                </button>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Name</label>
                                    <input
                                        type="text"
                                        value={editingProxy ? editingProxy.name : newProxyList.name}
                                        onChange={editingProxy
                                            ? (e) => setEditingProxy({ ...editingProxy, name: e.target.value })
                                            : handleNameChange
                                        }
                                        placeholder="Enter proxy list name"
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Proxies</label>
                                    <textarea
                                        value={editingProxy ? editingProxy.proxies : newProxyList.proxies}
                                        onChange={editingProxy
                                            ? (e) => setEditingProxy({ ...editingProxy, proxies: e.target.value })
                                            : handleProxiesChange
                                        }
                                        placeholder="Enter proxies (one per line)"
                                        className="w-full h-40 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 resize-none"
                                    />
                                </div>
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-3">
                                <button
                                    onClick={() => {
                                        setShowPopup(false);
                                        handleBlur()
                                        setEditingProxy(null);
                                    }}
                                    className="px-4 py-2 text-slate-300 hover:text-slate-100 text-sm transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        if (editingProxy) {
                                            saveProxyList(editingProxy);
                                            setEditingProxy(null);
                                        } else {
                                            createNewProxyList();
                                        }
                                        handleBlur()
                                        setShowPopup(false);
                                    }}
                                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    <Save size={16} />
                                    <span>{editingProxy ? 'Save Changes' : 'Create List'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default ProxySettings;
