import React, { useMemo } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
    ResponsiveContainer, Legend
} from 'recharts';
import { format } from 'date-fns';
import { StatusCode } from '../../../../types';

interface StatusCodeGraphProps {
    data: StatusCode[];
}

const statusCodeColors: { [key: string]: string } = {
    '200': '#10b981', // emerald-500
    '301': '#3b82f6', // blue-500
    '400': '#f59e0b', // amber-500
    '403': '#ef4444', // red-500
    '404': '#f97316', // orange-500
    '406': '#fbbf24', // yellow-400
    '500': '#6b7280', // gray-500
    '503': '#9ca3af', // gray-400
};

const statusCodeNames: { [key: string]: string } = {
    '200': '200 OK',
    '301': '301 Moved Permanently',
    '400': '400 Bad Request',
    '403': '403 Forbidden',
    '404': '404 Not Found',
    '406': '406 Not Acceptable',
    '500': '500 Internal Server Error',
    '503': '503 Service Unavailable',
};

const getStatusCodeColor = (statusCode: string): string =>
    statusCodeColors[statusCode] || '#94a3b8'; // slate-400 as fallback

const getStatusCodeName = (statusCode: string): string =>
    statusCodeNames[statusCode] || `Status ${statusCode}`;

const StatusCodeGraph: React.FC<StatusCodeGraphProps> = ({ data }) => {
    const uniqueStatusCodes: string[] = useMemo(() => {
        const codes = new Set<string>();
        data.forEach((entry) => {
            if (entry.counts) {
                Object.keys(entry.counts).forEach((code) => codes.add(code));
            }
        });
        return Array.from(codes).sort((a, b) => parseInt(a) - parseInt(b));
    }, [data]);

    if (!data || data.length === 0) {
        return (
            <div className="flex items-center justify-center h-full">
                <p className="text-slate-400 text-sm">No data available to display</p>
            </div>
        );
    }

    return (
        <div className="w-full h-full p-4 sm:p-6">
            <div className="mb-6">
                <h2 className="text-xl font-medium text-slate-100">Status Codes Over Time</h2>
                <p className="text-sm text-slate-400 mt-1">
                    Tracking response status codes from monitor requests
                </p>
            </div>

            <div className="h-[calc(100%-5rem)]">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data}
                        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            stroke="#1e293b" // slate-800
                            vertical={false}
                        />
                        <XAxis
                            dataKey="time"
                            type="number"
                            domain={['dataMin', 'dataMax']}
                            scale="time"
                            tickFormatter={(time) => format(new Date(time), 'HH:mm')}
                            stroke="#64748b" // slate-500
                            tick={{ fill: '#64748b', fontSize: 12 }}
                            axisLine={{ stroke: '#1e293b' }}
                            tickLine={{ stroke: '#1e293b' }}
                        />
                        <YAxis
                            stroke="#64748b"
                            tick={{ fill: '#64748b', fontSize: 12 }}
                            axisLine={{ stroke: '#1e293b' }}
                            tickLine={{ stroke: '#1e293b' }}
                            allowDecimals={false}
                            label={{
                                value: 'Count',
                                angle: -90,
                                position: 'insideLeft',
                                fill: '#64748b',
                                style: { fontSize: 12 }
                            }}
                        />
                        <Tooltip
                            labelFormatter={(time) => format(new Date(time), 'yyyy-MM-dd HH:mm')}
                            contentStyle={{
                                backgroundColor: '#1e293b', // slate-800
                                border: '1px solid #334155', // slate-700
                                borderRadius: '0.5rem',
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                color: '#e2e8f0' // slate-200
                            }}
                            formatter={(value, name) => [value, getStatusCodeName(name as string)]}
                            itemStyle={{ color: '#e2e8f0' }}
                            labelStyle={{ color: '#94a3b8' }} // slate-400
                        />
                        <Legend
                            formatter={(value) => (
                                <span style={{ color: getStatusCodeColor(value) }}>
                                    {getStatusCodeName(value)}
                                </span>
                            )}
                            wrapperStyle={{ paddingTop: '1rem' }}
                        />
                        {uniqueStatusCodes.map((statusCode) => (
                            <Line
                                key={statusCode}
                                type="stepAfter"
                                dataKey={`counts.${statusCode}`}
                                name={statusCode}
                                stroke={getStatusCodeColor(statusCode)}
                                strokeWidth={2}
                                dot={false}
                                activeDot={{
                                    r: 4,
                                    strokeWidth: 2,
                                    fill: getStatusCodeColor(statusCode),
                                    stroke: '#0f172a' // slate-900
                                }}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default StatusCodeGraph;