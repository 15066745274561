import React from 'react';
import { AlertCircle } from 'lucide-react';

const InputsNotAllowedMessage: React.FC = () => (
    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
        <div className="p-4 sm:p-6">
            <div className="flex items-start gap-4">
                <div className="flex-shrink-0">
                    <div className="p-2 bg-yellow-500/10 rounded-lg border border-yellow-500/20">
                        <AlertCircle size={20} className="text-yellow-400" />
                    </div>
                </div>
                <div className="flex-1 space-y-1">
                    <h4 className="font-medium text-yellow-400">
                        Inputs Not Allowed
                    </h4>
                    <p className="text-sm text-slate-300">
                        This monitor is configured to operate with predefined inputs only. Custom inputs are currently disabled for this specific monitor setup.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default InputsNotAllowedMessage;