import { classNames } from "../../../utils/classNames";
import { MenuItem } from "./MenuItem";

export const SubMenu = ({
    items,
    isCollapsed,
    currentPath,
    onNavigate,
    isMobile
}: {
    items: Array<{ icon: React.ElementType; label: string; path: string }>;
    isCollapsed: boolean;
    currentPath: string;
    onNavigate: (path: string) => void;
    isMobile?: boolean;
}) => {
    return (
        <div className={classNames(
            "space-y-1",
            !isMobile && "ml-4"
        )}>
            {items.map((item) => (
                <MenuItem
                    key={item.path}
                    icon={item.icon}
                    label={item.label}
                    isActive={currentPath === item.path}
                    isCollapsed={isCollapsed}
                    onClick={() => onNavigate(item.path)}
                    isMobile={isMobile}
                />
            ))}
        </div>
    );
};