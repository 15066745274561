import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { Check, X } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../../constants';






interface PermissionData {
    channels?: {
        id: string;
        permission: boolean;
    }[];
    members?: {
        id: string;
        permission: boolean;
    }[];
    roles?: {
        id: string;
        permission: boolean;
    }[];
}

interface Command {
    id: string;
    name: string;
    permissions: PermissionData;
}

interface Roles {
    id: string;
    name: string;
    color: number;
}

interface Members {
    id: string;
    name: string;
    avatar: string;
}

interface Channels {
    id: string;
    name: string;
}

interface ExtendedRoles extends Roles {
    permission: boolean;
}

interface ExtendedMembers extends Members {
    permission: boolean;
}

interface ExtendedChannels extends Channels {
    permission: boolean;
}

interface PopupProps {
    current_command: Command;
    handle_close: () => void;
    handle_show_success: () => void;
    roles: Roles[];
    members: Members[];
    channels: Channels[];
    server_id: string;
    app_id: string;
}



const OptionWithImage = (props: any) => (
    <components.Option {...props}>
        <div className="flex items-center gap-2 px-1 py-0.5">
            <img
                src={props.data.avatar}
                alt={props.data.name}
                className="w-5 h-5 rounded-full object-cover"
                onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = 'https://cdn.discordapp.com/embed/avatars/0.png';
                }}
            />
            <span className="text-sm">{props.data.name}</span>
        </div>
    </components.Option>
);

const SingleValueWithImage = (props: any) => (
    <components.SingleValue {...props}>
        <div className="flex items-center gap-2">
            <img
                src={props.data.avatar}
                alt={props.data.name}
                className="w-5 h-5 rounded-full object-cover"
                onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = 'https://cdn.discordapp.com/embed/avatars/0.png';
                }}
            />
            <span className="text-sm">{props.data.name}</span>
        </div>
    </components.SingleValue>
);

const combineWithPermissionRoles = (selectedItems: Roles[], permissionItems: any[]): ExtendedRoles[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const combineWithPermissionChannels = (selectedItems: Channels[], permissionItems: any[]): ExtendedChannels[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const combineWithPermissionMembers = (selectedItems: Members[], permissionItems: any[]): ExtendedMembers[] => {
    return selectedItems.map(item => {
        const matchingPermission = permissionItems.find(pItem => pItem.id === item.id);
        const permission = matchingPermission ? matchingPermission.permission : false;
        return { ...item, permission };
    });
}

const PermissionsPopup: React.FC<PopupProps> = ({ current_command, handle_close, roles, members, channels, server_id, app_id, handle_show_success }) => {
    const [selectedRoles, setSelectedRoles] = useState<Roles[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<Members[]>([]);
    const [selectedChannels, setSelectedChannels] = useState<Channels[]>([]);
    const [showEdit, setShowEdit] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState("");
    const [showLastStep, setShowLastStep] = useState(false);
    const [mode, setMode] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);

    const permission = current_command.permissions;

    const [selectedRolesNew, setSelectedRolesNew] = useState<ExtendedRoles[]>(
        combineWithPermissionRoles(selectedRoles, permission.roles || [])
    );
    const [selectedMembersNew, setSelectedMembersNew] = useState<ExtendedMembers[]>(
        combineWithPermissionMembers(selectedMembers, permission.members || [])
    );
    const [selectedChannelsNew, setSelectedChannelsNew] = useState<ExtendedChannels[]>(
        combineWithPermissionChannels(selectedChannels, permission.channels || [])
    );

    useEffect(() => {
        setSelectedRolesNew(combineWithPermissionRoles(selectedRoles, permission.roles || []));
    }, [selectedRoles, permission.roles]);

    useEffect(() => {
        setSelectedMembersNew(combineWithPermissionMembers(selectedMembers, permission.members || []));
    }, [selectedMembers, permission.members]);

    useEffect(() => {
        setSelectedChannelsNew(combineWithPermissionChannels(selectedChannels, permission.channels || []));
    }, [selectedChannels, permission.channels]);

    useEffect(() => {
        const sum = selectedChannels.length + selectedMembers.length + selectedRoles.length;
        setBtnDisabled(sum === 0);
    }, [selectedChannels, selectedMembers, selectedRoles]);

    const handleModeChange = (selectedOption) => {
        setMode(selectedOption.value);
    };

    const handleRolePermissionChange = (roleId: string) => {
        setSelectedRolesNew(prevState => prevState.map(role =>
            role.id === roleId ? { ...role, permission: !role.permission } : role
        ));
    };

    const handleMemberPermissionChange = (memberId: string) => {
        setSelectedMembersNew(prevState => prevState.map(member =>
            member.id === memberId ? { ...member, permission: !member.permission } : member
        ));
    };

    const handleChannelPermissionChange = (channelId: string) => {
        setSelectedChannelsNew(prevState => prevState.map(channel =>
            channel.id === channelId ? { ...channel, permission: !channel.permission } : channel
        ));
    };

    const handleSelectedMembers = (selected) => {
        setSelectedMembers(selected);
    };

    const handleSelectedChannels = (selected) => {
        setSelectedChannels(selected);
    };

    const handleSelectedRoles = (selected) => {
        setSelectedRoles(selected);
    };
    const selectStyles = {
        control: (base: any) => ({
            ...base,
            backgroundColor: '#1a2234',
            borderColor: 'rgba(148, 163, 184, 0.2)',
            '&:hover': {
                borderColor: 'rgba(148, 163, 184, 0.3)'
            }
        }),
        menu: (base: any) => ({
            ...base,
            backgroundColor: '#1a2234',
            border: '1px solid rgba(148, 163, 184, 0.2)',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }),
        option: (base: any, state: any) => ({
            ...base,
            backgroundColor: state.isFocused ? '#2d3748' : 'transparent',
            '&:active': {
                backgroundColor: '#2d3748',
            }
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#ffffff'
        }),
        multiValue: (base: any) => ({
            ...base,
            backgroundColor: '#2d3748'
        }),
        multiValueLabel: (base: any) => ({
            ...base,
            color: '#e2e8f0'
        }),
        multiValueRemove: (base: any) => ({
            ...base,
            color: '#e2e8f0',
            '&:hover': {
                backgroundColor: '#4a5568',
                color: '#fff'
            }
        })
    };

    const handleSave = async () => {
        try {
            const response = await axios.post(`${API_URL}/v1/update/bot/commands`, {
                selected_channels: selectedChannelsNew,
                selected_roles: selectedRolesNew,
                selected_members: selectedMembersNew,
                app_id: app_id,
                guild_id: server_id,
                command_id: current_command.id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                handle_show_success();
                handle_close();
            } else if (response.status === 203) {
                setErrorUpdate("ERROR: Not enough permission!");
            } else {
                setErrorUpdate("An error occurred!");
            }
        } catch {
            setErrorUpdate("An error occurred!");
        }
    };

    const roleWithLabel = roles.map((role) => ({
        ...role,
        value: role.id,
        label: role.name,
    }));

    const membersWithLabel = members.map((member) => ({
        ...member,
        label: member.name,
        value: member.id,
    }));

    const channelsWithLabel = channels.map((channel) => ({
        ...channel,
        label: channel.name,
        value: channel.id,
    }));

    const modeOptions = [
        { "label": "Channel", "value": "channel" },
        { "label": "Role", "value": "role" },
        { "label": "Member", "value": "member" }
    ];

    const handleBackMenu = () => {
        clearSelections();
        setShowEdit(false);
    };

    const handleBackEdit = () => {
        setShowEdit(true);
        setShowLastStep(false);
    };

    const handleLastStep = () => {
        setShowEdit(false);
        setShowLastStep(true);
    };

    const changeEdit = () => {
        setShowEdit(!showEdit);
        loopThroughData();
    };

    const getPermissionSymbol = (permission) => {
        return permission ? <Check color="white" /> : <X color="white" />;
    };

    const loopThroughData = () => {
        if (permission.channels && permission.members && permission.roles) {
            permission.members.forEach((member) => {
                const matchedMember = members.find((item) => item.id === member.id);
                if (matchedMember) {
                    const memberNew = {
                        ...matchedMember,
                        label: matchedMember.name,
                        value: matchedMember.id,
                    };
                    setSelectedMembers((prevMembers) => [...prevMembers, memberNew]);
                }
            });

            permission.roles.forEach((role) => {
                const matchedRole = roles.find((item) => item.id === role.id);
                if (matchedRole) {
                    const roleNew = {
                        ...matchedRole,
                        label: matchedRole.name,
                        value: matchedRole.id,
                        color: matchedRole.color,
                    };
                    setSelectedRoles((prevRoles) => [...prevRoles, roleNew]);
                }
            });

            permission.channels.forEach((channel) => {
                const matchedChannel = channels.find((item) => item.id.toString() === channel.id.toString());
                if (matchedChannel) {
                    const channelNew = {
                        ...matchedChannel,
                        label: matchedChannel.name,
                        value: matchedChannel.id,
                    };
                    setSelectedChannels((prevChannels) => [...prevChannels, channelNew]);
                }
            });
        }
    };

    const mapIdsToNames = (data: PermissionData) => {
        const idToNameMap = new Map<string, string>();

        roles.forEach((role) => {
            idToNameMap.set(role.id, role.name);
        });

        members.forEach((member) => {
            idToNameMap.set(member.id, member.name);
        });

        channels.forEach((channel) => {
            idToNameMap.set(channel.id, channel.name);
        });

        if (permission.channels && permission.members && permission.roles) {
            return {
                ...data,
                channels: permission.channels.map((channel) => ({
                    id: idToNameMap.get(channel.id) || channel.id,
                    permission: channel.permission,
                })),
                members: permission.members.map((member) => ({
                    id: idToNameMap.get(member.id) || member.id,
                    permission: member.permission,
                })),
                roles: permission.roles.map((role) => ({
                    id: idToNameMap.get(role.id) || role.id,
                    permission: role.permission,
                })),
            };
        }
        return data;
    };

    const clearSelections = () => {
        setSelectedChannels([]);
        setSelectedRoles([]);
        setSelectedMembers([]);
    };

    const updatedData = mapIdsToNames(permission);

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Backdrop */}
            <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" onClick={handle_close} />

            {/* Modal Container */}
            <div className="relative w-[800px] max-h-[85vh] bg-[#0f1729] rounded-xl shadow-2xl flex flex-col overflow-hidden">
                {/* Header */}
                <div className="flex items-center justify-between px-6 py-4 border-b border-slate-700/50">
                    <div className="flex items-center gap-3">
                        <h2 className="text-lg font-semibold text-slate-100">{current_command.name}</h2>
                        <span className="px-2.5 py-0.5 text-xs font-medium text-slate-400 bg-slate-800 rounded-full">
                            Command Permissions
                        </span>
                    </div>
                    <button
                        onClick={handle_close}
                        className="p-1.5 text-slate-400 hover:text-slate-300 rounded-lg hover:bg-slate-800/60 transition-colors"
                    >
                        <X size={18} />
                    </button>
                </div>

                {/* Content Area */}
                <div className="flex-1 overflow-y-auto">
                    <div className="px-6 py-4 space-y-6">
                        {/* Edit Mode */}
                        {showEdit && (
                            <div className="space-y-6">
                                <div className="bg-slate-800/40 rounded-lg p-4">
                                    <label className="block text-sm font-medium text-slate-300 mb-2">
                                        Permission Mode
                                    </label>
                                    <Select
                                        value={modeOptions.find(option => option.value === mode)}
                                        onChange={handleModeChange}
                                        options={modeOptions}
                                        isSearchable={false}
                                        styles={selectStyles}
                                        className="text-sm text-white"
                                        classNamePrefix="select"
                                        placeholder="Select permission type..."
                                    />
                                </div>

                                {mode && (
                                    <div className="bg-slate-800/40 rounded-lg p-4 space-y-4">
                                        {mode === "channel" && (
                                            <div>
                                                <label className="block text-sm font-medium text-slate-300 mb-2">Channels</label>
                                                <Select
                                                    isMulti
                                                    value={selectedChannels}
                                                    onChange={handleSelectedChannels}
                                                    options={channelsWithLabel}
                                                    styles={selectStyles}
                                                    className="text-sm text-white"
                                                    classNamePrefix="select"
                                                    placeholder="Select channels..."
                                                />
                                            </div>
                                        )}

                                        {mode === "role" && (
                                            <div>
                                                <label className="block text-sm font-medium text-slate-300 mb-2">Roles</label>
                                                <Select
                                                    isMulti
                                                    value={selectedRoles}
                                                    onChange={handleSelectedRoles}
                                                    options={roleWithLabel}
                                                    styles={selectStyles}
                                                    className="text-sm text-white"
                                                    classNamePrefix="select"
                                                    placeholder="Select roles..."
                                                />
                                            </div>
                                        )}

                                        {mode === "member" && (
                                            <div>
                                                <label className="block text-sm font-medium text-slate-300 mb-2">Members</label>
                                                <Select
                                                    isMulti
                                                    value={selectedMembers}
                                                    onChange={handleSelectedMembers}
                                                    options={membersWithLabel}
                                                    components={{ Option: OptionWithImage, SingleValue: SingleValueWithImage }}
                                                    styles={selectStyles}
                                                    className="text-sm text-white"
                                                    classNamePrefix="select"
                                                    placeholder="Select members..."
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Selection Summary */}
                                {(selectedChannels.length > 0 || selectedRoles.length > 0 || selectedMembers.length > 0) && (
                                    <div className="bg-slate-800/40 rounded-lg divide-y divide-slate-700/50">
                                        {selectedChannels.length > 0 && (
                                            <div className="p-4">
                                                <h4 className="text-xs font-medium text-slate-400 mb-3">
                                                    Selected Channels ({selectedChannels.length})
                                                </h4>
                                                <div className="flex flex-wrap gap-2">
                                                    {selectedChannels.map((channel) => (
                                                        <span key={channel.id}
                                                            className="inline-flex items-center px-2.5 py-1 bg-slate-700/50 text-slate-300 text-xs rounded">
                                                            # {channel.name}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {selectedRoles.length > 0 && (
                                            <div className="p-4">
                                                <h4 className="text-xs font-medium text-slate-400 mb-3">
                                                    Selected Roles ({selectedRoles.length})
                                                </h4>
                                                <div className="flex flex-wrap gap-2">
                                                    {selectedRoles.map((role) => (
                                                        <span key={role.id}
                                                            className="inline-flex items-center px-2.5 py-1 bg-slate-700/50 text-slate-300 text-xs rounded">
                                                            @ {role.name}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {selectedMembers.length > 0 && (
                                            <div className="p-4">
                                                <h4 className="text-xs font-medium text-slate-400 mb-3">
                                                    Selected Members ({selectedMembers.length})
                                                </h4>
                                                <div className="flex flex-wrap gap-2">
                                                    {selectedMembers.map((member) => (
                                                        <div key={member.id}
                                                            className="inline-flex items-center gap-2 px-2.5 py-1 bg-slate-700/50 text-slate-300 text-xs rounded">
                                                            <img src={member.avatar} alt="" className="w-4 h-4 rounded-full" />
                                                            {member.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Current Permissions View */}
                        {!showEdit && !showLastStep && (
                            <div className="space-y-6">
                                {(!updatedData || (!updatedData.channels?.length && !updatedData.members?.length && !updatedData.roles?.length)) ? (
                                    <div className="text-center py-8">
                                        <p className="text-sm text-slate-400">No customized permissions configured</p>
                                    </div>
                                ) : (
                                    <div className="space-y-4">
                                        {updatedData?.channels && updatedData.channels.length > 0 && (
                                            <div className="bg-slate-800/40 rounded-lg p-4">
                                                <h3 className="text-sm font-medium text-slate-300 mb-3">Channel Permissions</h3>
                                                <div className="space-y-2">
                                                    {updatedData.channels.map((channel) => (
                                                        <div key={channel.id}
                                                            className="flex items-center justify-between p-2 bg-slate-700/30 rounded-md">
                                                            <span className="text-sm text-slate-300">#{channel.id}</span>
                                                            {getPermissionSymbol(channel.permission)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {updatedData?.roles && updatedData.roles.length > 0 && (
                                            <div className="bg-slate-800/40 rounded-lg p-4">
                                                <h3 className="text-sm font-medium text-slate-300 mb-3">Role Permissions</h3>
                                                <div className="space-y-2">
                                                    {updatedData.roles.map((role) => (
                                                        <div key={role.id}
                                                            className="flex items-center justify-between p-2 bg-slate-700/30 rounded-md">
                                                            <span className="text-sm text-slate-300">@{role.id}</span>
                                                            {getPermissionSymbol(role.permission)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {updatedData?.members && updatedData.members.length > 0 && (
                                            <div className="bg-slate-800/40 rounded-lg p-4">
                                                <h3 className="text-sm font-medium text-slate-300 mb-3">Member Permissions</h3>
                                                <div className="space-y-2">
                                                    {updatedData.members.map((member) => (
                                                        <div key={member.id}
                                                            className="flex items-center justify-between p-2 bg-slate-700/30 rounded-md">
                                                            <span className="text-sm text-slate-300">{member.id}</span>
                                                            {getPermissionSymbol(member.permission)}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Permission Settings Step */}
                        {!showEdit && showLastStep && (
                            <div className="space-y-4">
                                {selectedRolesNew.length > 0 && (
                                    <div className="bg-slate-800/40 rounded-lg p-4">
                                        <h3 className="text-sm font-medium text-slate-300 mb-3">Configure Role Permissions</h3>
                                        <div className="space-y-2">
                                            {selectedRolesNew.map(role => (
                                                <label key={role.id}
                                                    className="flex items-center justify-between p-2 bg-slate-700/30 rounded-md cursor-pointer hover:bg-slate-700/50">
                                                    <span className="text-sm text-slate-300">@{role.name}</span>
                                                    <input
                                                        type="checkbox"
                                                        checked={role.permission}
                                                        onChange={() => handleRolePermissionChange(role.id)}
                                                        className="w-4 h-4 rounded border-slate-600 text-blue-600 focus:ring-blue-500 focus:ring-offset-slate-800"
                                                    />
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {selectedChannelsNew.length > 0 && (
                                    <div className="bg-slate-800/40 rounded-lg p-4">
                                        <h3 className="text-sm font-medium text-slate-300 mb-3">Configure Channel Permissions</h3>
                                        <div className="space-y-2">
                                            {selectedChannelsNew.map(channel => (
                                                <label key={channel.id}
                                                    className="flex items-center justify-between p-2 bg-slate-700/30 rounded-md cursor-pointer hover:bg-slate-700/50">
                                                    <span className="text-sm text-slate-300">#{channel.name}</span>
                                                    <input
                                                        type="checkbox"
                                                        checked={channel.permission}
                                                        onChange={() => handleChannelPermissionChange(channel.id)}
                                                        className="w-4 h-4 rounded border-slate-600 text-blue-600 focus:ring-blue-500 focus:ring-offset-slate-800"
                                                    />
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {selectedMembersNew.length > 0 && (
                                    <div className="bg-slate-800/40 rounded-lg p-4">
                                        <h3 className="text-sm font-medium text-slate-300 mb-3">Configure Member Permissions</h3>
                                        <div className="space-y-2">
                                            {selectedMembersNew.map(member => (
                                                <label key={member.id}
                                                    className="flex items-center justify-between p-2 bg-slate-700/30 rounded-md cursor-pointer hover:bg-slate-700/50">
                                                    <div className="flex items-center gap-2">
                                                        <img src={member.avatar} alt="" className="w-5 h-5 rounded-full" />
                                                        <span className="text-sm text-slate-300">{member.name}</span>
                                                    </div>
                                                    <input
                                                        type="checkbox"
                                                        checked={member.permission}
                                                        onChange={() => handleMemberPermissionChange(member.id)}
                                                        className="w-4 h-4 rounded border-slate-600 text-blue-600 focus:ring-blue-500 focus:ring-offset-slate-800"
                                                    />
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* Footer */}
                <div className="px-6 py-4 border-t border-slate-700/50">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                            {showEdit && (
                                <>
                                    <button
                                        onClick={handleBackMenu}
                                        className="px-4 py-2 text-sm font-medium text-slate-300 hover:text-white bg-slate-700/50 hover:bg-slate-700 rounded-lg transition-colors"
                                    >
                                        Back
                                    </button>
                                    <button
                                        onClick={handleLastStep}
                                        disabled={btnDisabled}
                                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        Continue
                                    </button>
                                </>
                            )}

                            {!showEdit && !showLastStep && (
                                <>
                                    <button
                                        onClick={handle_close}
                                        className="px-4 py-2 text-sm font-medium
                                        text-slate-300 hover:text-white bg-slate-700/50 hover:bg-slate-700 rounded-lg transition-colors"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={changeEdit}
                                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                                    >
                                        Edit Permissions
                                    </button>
                                </>
                            )}

                            {!showEdit && showLastStep && (
                                <>
                                    <button
                                        onClick={handleBackEdit}
                                        className="px-4 py-2 text-sm font-medium text-slate-300 hover:text-white bg-slate-700/50 hover:bg-slate-700 rounded-lg transition-colors"
                                    >
                                        Back
                                    </button>
                                    <button
                                        onClick={handleSave}
                                        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                                    >
                                        Save Changes
                                    </button>
                                </>
                            )}
                        </div>

                        {errorUpdate && (
                            <p className="text-sm text-red-400">{errorUpdate}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PermissionsPopup;