import React, { useState, useEffect } from "react";
import axios from "axios";
import { UserPlus, Copy, X, Trash2, Shield } from "lucide-react";
import ReactDOM from 'react-dom';
import { API_URL } from "../../constants";
import { useLoading } from "../../contexts/LoadingContext";

interface AccessProps {
    handleLogOut: () => void;
    handleBlur: () => void;
    app_id: string;
}

interface User {
    discord_id: string;
    icon: string;
    name: string;
    permission: number;
}

const DashboardAccess: React.FC<AccessProps> = ({ handleLogOut, handleBlur, app_id }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [showPopup, setShowPopup] = useState(false);
    const [inviteLink, setInviteLink] = useState("");
    const [copyStatus, setCopyStatus] = useState("Copy");
    const [errorMessage, setErrorMessage] = useState("");

    const { setIsLoading } = useLoading();


    useEffect(() => {
        getDashboardUsers();
    }, []);

    const getDashboardUsers = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/v1/user/info/bot/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setUsers(response.data.data.bots[0].users);
            }
        } catch (error) {
            handleLogOut();
        }
        finally {
            setIsLoading(false);
        }
    };

    const getExpirationDate = () => {
        const expirationDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        return expirationDate.toLocaleString('en-EU', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        });
    };

    const handleInvitePopup = async () => {
        setShowPopup(!showPopup);
        handleBlur();
        if (!showPopup) {
            try {
                const response = await axios.post(`${API_URL}/v1/create/bot/invite`, {
                    app_id: app_id,
                    permission: 2,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });
                if (response.status === 200) {
                    setInviteLink(response.data.data.link);
                } else if (response.status === 203) {
                    setErrorMessage("ERROR: Not enough permission!");
                } else {
                    setErrorMessage("An error occurred!");
                }
            } catch {
                setErrorMessage("An error occurred!");
            }
        } else {
            setInviteLink("");
            setCopyStatus("Copy");
        }
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(inviteLink);
            setCopyStatus("Copied!");
            setTimeout(() => setCopyStatus("Copy"), 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const removeUser = async (user: User) => {
        try {
            const response = await axios.post(`${API_URL}/v1/remove/bot/user`, {
                app_id: app_id,
                user_id: user.discord_id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                getDashboardUsers();
            }
        } catch {
            setErrorMessage("An error occurred!");
        }
    };

    const idToStr = (id: number) => {
        switch (id) {
            case 1: return "Owner";
            case 2: return "Admin";
            default: return "N/A";
        }
    };

    const getPermissionColor = (permission: number) => {
        switch (permission) {
            case 1: return "text-yellow-400";
            case 2: return "text-blue-400";
            default: return "text-slate-400";
        }
    };

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729]">
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-8">Dashboard Access</h1>

                <div className="max-w-3xl mx-auto space-y-6">
                    <div className="flex justify-between items-center">
                        <p className="text-sm text-slate-400">
                            Manage access to your dashboard and invite new administrators
                        </p>
                        <button
                            onClick={handleInvitePopup}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                        >
                            <UserPlus size={16} />
                            <span>Invite User</span>
                        </button>
                    </div>

                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        {users.map((user, index) => (
                            <div
                                key={user.discord_id}
                                className={`p-4 flex items-center justify-between ${index !== users.length - 1 ? 'border-b border-slate-700/50' : ''
                                    }`}
                            >
                                <div className="flex items-center gap-4">
                                    <div className="relative">
                                        <img
                                            src={user.icon}
                                            alt={user.name}
                                            className="w-10 h-10 rounded-full ring-2 ring-slate-700/50"
                                        />
                                        {user.permission === 1 && (
                                            <div className="absolute -bottom-1 -right-1">
                                                <Shield size={16} className="text-yellow-400 fill-yellow-400" />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <h3 className="font-medium text-slate-100">{user.name}</h3>
                                        <span className={`text-sm ${getPermissionColor(user.permission)}`}>
                                            {idToStr(user.permission)}
                                        </span>
                                    </div>
                                </div>
                                {user.permission !== 1 && (
                                    <button
                                        onClick={() => removeUser(user)}
                                        className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                    >
                                        <Trash2 size={16} className="text-slate-400 group-hover:text-red-400" />
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showPopup && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-150">
                    <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-6">
                                <h2 className="text-xl font-semibold text-slate-100">Invite User</h2>
                                <button
                                    onClick={handleInvitePopup}
                                    className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors"
                                >
                                    <X size={16} className="text-slate-400" />
                                </button>
                            </div>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Invite Link</label>
                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            value={inviteLink}
                                            readOnly
                                            className="flex-1 bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                        />
                                        <button
                                            onClick={handleCopy}
                                            className="px-3 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors flex items-center gap-2"
                                        >
                                            <Copy size={16} />
                                            <span>{copyStatus}</span>
                                        </button>
                                    </div>
                                </div>

                                <p className="text-xs text-slate-400">
                                    This invite link will expire at {getExpirationDate()} and can only be used once
                                </p>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}

            {errorMessage && (
                <div className="fixed bottom-4 right-4 bg-red-500/10 border border-red-500/20 text-red-400 px-4 py-2 rounded-lg">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default DashboardAccess;