import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BotData, Customer } from '../types';
import PaymentView from '../components/Sidebar/Payment';

interface SettingsPageProps {
    botData: BotData;
    customerData: Customer;
}

export const PaymentPage: React.FC<SettingsPageProps> = ({ botData, customerData }) => {
    const navigate = useNavigate();

    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }

    return (
        <div >
            <PaymentView customerData={customerData} />
        </div>
    );
};