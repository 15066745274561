import React from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { MonitorInput, Customer } from '../../../../types';

interface OptionalInputsProps {
    showOptionalInputs: boolean;
    setShowOptionalInputs: (show: boolean) => void;
    newItem: MonitorInput;
    setNewItem: (item: MonitorInput) => void;
    handleSizeRangeChange: (e: React.ChangeEvent<HTMLInputElement>, type: 'from' | 'to', item: MonitorInput | null) => void;
    customerData: Customer | null
}

const OptionalInputs: React.FC<OptionalInputsProps> = ({
    showOptionalInputs,
    setShowOptionalInputs,
    newItem,
    setNewItem,
    handleSizeRangeChange,
    customerData
}) => (
    <div className="bg-[#1a2234] rounded-xl overflow-hidden border border-slate-700/50">
        <button
            onClick={() => setShowOptionalInputs(!showOptionalInputs)}
            className="w-full p-4 text-sm text-slate-100 hover:bg-slate-700/30 transition-colors flex items-center justify-between"
        >
            <span className="font-medium">Optional Settings</span>
            {showOptionalInputs ? (
                <ChevronUp size={16} className="text-slate-400" />
            ) : (
                <ChevronDown size={16} className="text-slate-400" />
            )}
        </button>

        {showOptionalInputs && (
            <div className="p-4 space-y-4 border-t border-slate-700/50">
                <div className="space-y-2">
                    <label className="block text-sm text-slate-400">
                        Size Range: <span className="text-slate-300">{newItem.sizeRange}</span>
                    </label>
                    <div className="flex items-center gap-4">
                        <span className="text-xs text-slate-400">36</span>
                        <div className="flex-1 flex gap-2">
                            <input
                                type="range"
                                min="36"
                                max="50"
                                value={newItem.sizeRange.split('-')[0]}
                                onChange={(e) => handleSizeRangeChange(e, 'from', null)}
                                className="flex-1 accent-blue-500"
                            />
                            <input
                                type="range"
                                min="36"
                                max="50"
                                value={newItem.sizeRange.split('-')[1]}
                                onChange={(e) => handleSizeRangeChange(e, 'to', null)}
                                className="flex-1 accent-blue-500"
                            />
                        </div>
                        <span className="text-xs text-slate-400">50</span>
                    </div>
                </div>

                {customerData?.purchases.monitor.includes("private inputs") && (
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="privateCheckbox"
                            checked={newItem.private || false}
                            onChange={(e) => setNewItem({ ...newItem, private: e.target.checked })}
                            className="w-4 h-4 rounded border-slate-700/50 bg-[#0f1729] text-blue-500 focus:ring-blue-500 focus:ring-offset-0"
                        />
                        <label htmlFor="privateCheckbox" className="text-sm text-slate-300">
                            Private Input
                        </label>
                    </div>
                )}

                <div>
                    <label className="block text-sm text-slate-400 mb-1">Special Webhook</label>
                    <input
                        type="text"
                        placeholder="Enter webhook URL"
                        value={newItem.specialWebhook}
                        onChange={(e) => setNewItem({ ...newItem, specialWebhook: e.target.value })}
                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                    />
                </div>

                <div>
                    <label className="block text-sm text-slate-400 mb-1">Note</label>
                    <input
                        type="text"
                        placeholder="Add a note"
                        value={newItem.note}
                        onChange={(e) => setNewItem({ ...newItem, note: e.target.value })}
                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                    />
                </div>

                <div>
                    <label className="block text-sm text-slate-400 mb-1">Price Limit</label>
                    <input
                        type="number"
                        placeholder="Set price limit"
                        value={newItem.priceLimit || ''}
                        onChange={(e) => setNewItem({
                            ...newItem,
                            priceLimit: e.target.value ? Number(e.target.value) : undefined
                        })}
                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                    />
                </div>
            </div>
        )}
    </div>
);

export default OptionalInputs;