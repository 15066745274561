import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BotData, Customer, Monitor } from '../../types';
import MonitorSettings from '../../components/Sidebar/Monitor/MonitorSettings/MonitorSettings';
import axios from 'axios';
import { API_URL } from '../../constants';
import { useLoading } from '../../contexts/LoadingContext';

interface MonitorDetailsPageProps {
    botData: BotData;
    customerData: Customer;
    handleLogout: () => void;
    handleBlur: () => void;
    developer: boolean;
}

export const MonitorDetailsPage: React.FC<MonitorDetailsPageProps> = ({ botData, handleLogout, customerData, handleBlur, developer }) => {
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();

    const [currentMonitor, setCurrentMonitor] = useState<Monitor | null>(null);
    const [tempMonitor, setTempMonitor] = useState<Monitor | null>(null);


    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { uuid } = useParams<{ uuid?: string }>();

    const handleBackToTable = () => {
        navigate('/monitors');
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getCurrentMonitor = useCallback(async () => {
        try {
            setIsLoading(true);
            if (uuid) {
                const response = await axios.get(`${API_URL}/v1/monitors/${uuid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || ""
                    }
                });
                if (response.status === 200) {
                    const currentMonitorScrape = response.data.monitor;
                    setCurrentMonitor(currentMonitorScrape);
                    setTempMonitor(currentMonitorScrape);
                }
            }
        } catch (error) {
            console.log("monitor not found")
            navigate('/monitors');
        } finally {
            setIsLoading(false);

        }
    }, [navigate, setIsLoading, uuid]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (uuid != null && (currentMonitor === null)) {
            getCurrentMonitor();
        }
    }, [uuid, currentMonitor, getCurrentMonitor]);

    return (
        <div>
            {currentMonitor && (
                <MonitorSettings currentMonitor={currentMonitor} handleBackToTable={handleBackToTable} setCurrentMonitor={setCurrentMonitor} setTempMonitor={setTempMonitor} handleLogOut={handleLogout} handleBlur={handleBlur} tempMonitor={tempMonitor} developer={developer} customerData={customerData} />

            )}
        </div>
    );
};