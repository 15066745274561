import React from 'react';
import { WebhookIcon } from 'lucide-react';
import { Monitor } from '../../../../types';

interface WebhookSectionProps {
    currentMonitor: Monitor;
    setCurrentMonitor: (monitor: Monitor) => void;
    setHasChanged: (changed: boolean) => void;
}

const WebhookSection: React.FC<WebhookSectionProps> = ({
    currentMonitor,
    setCurrentMonitor,
    setHasChanged
}) => {
    const handleWebhookChange = (event: React.ChangeEvent<HTMLInputElement>, region: string) => {
        const updatedWebhooks = currentMonitor.webhooks.map((webhook) =>
            webhook.region === region
                ? { ...webhook, webhook_url: event.target.value }
                : webhook
        );

        setCurrentMonitor({
            ...currentMonitor,
            webhooks: updatedWebhooks,
        });
        setHasChanged(true);
    };

    return (
        <div className="bg-[#1a2234] rounded-xl overflow-hidden">
            <div className="p-4 sm:p-6">
                <div className="flex items-center mb-6">
                    <h3 className="text-xl font-medium text-slate-100 flex items-center">Webhook Settings</h3>
                    <span className="ml-2 flex items-center justify-center min-w-[1.5rem] h-6 text-xs rounded-full bg-slate-500/10 text-slate-400 border border-slate-500/20">
                        {currentMonitor.webhooks.length}
                    </span>
                </div>

                <div className="space-y-4">
                    {currentMonitor.webhooks.map((webhook) => (
                        <div key={webhook.region}>
                            <div className="flex items-center gap-2 mb-1.5">
                                <div className="p-1 rounded-md bg-blue-500/10 border border-blue-500/20">
                                    <WebhookIcon size={12} className="text-blue-400" />
                                </div>
                                <label className="text-sm text-slate-400">
                                    {webhook.region} Webhook
                                </label>
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    value={webhook.webhook_url}
                                    onChange={(e) => handleWebhookChange(e, webhook.region)}
                                    placeholder="Enter Discord webhook URL"
                                    className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 placeholder-slate-500"
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {currentMonitor.webhooks.length === 0 && (
                    <div className="py-8">
                        <div className="flex flex-col items-center gap-2 text-center">
                            <WebhookIcon size={24} className="text-slate-400" />
                            <p className="text-sm text-slate-400">
                                No webhooks configured
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WebhookSection;