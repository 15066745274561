import React, { useState, useEffect } from "react";
import { Filter, ChevronDown, ChevronUp, Search, Edit2 } from 'lucide-react';

interface MonitorData {
    monitor_name: string;
    monitor_uuid: string;
    input_allowed: boolean;
    monitor_type: string;
    product: string;
    product_name: string;
    example_inputs: string[];
    status?: string
}

interface MonitorTableProps {
    monitorsPreview: MonitorData[];
    handleEdit: (monitor: MonitorData) => void;
    developer: Boolean;
}

const MonitorTable: React.FC<MonitorTableProps> = ({ monitorsPreview, handleEdit, developer }) => {
    const [selectedFilter, setSelectedFilter] = useState<string>("All");
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [filteredMonitors, setFilteredMonitors] = useState<MonitorData[]>(monitorsPreview);
    const filters = ['All', 'Instore', 'Online'];
    if (developer) {
        filters.push("Blocked")
    }

    useEffect(() => {
        let tempFiltered = monitorsPreview;

        if (selectedFilter !== "All") {
            if (selectedFilter === "Blocked") {
                tempFiltered = tempFiltered.filter(monitor => monitor.status?.toLowerCase() === selectedFilter.toLowerCase());
            }
            else {
                tempFiltered = tempFiltered.filter(monitor => monitor.product_name.toLowerCase() === selectedFilter.toLowerCase());
            }
        }

        if (searchTerm !== "") {
            tempFiltered = tempFiltered.filter(monitor =>
                monitor.monitor_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredMonitors(tempFiltered);
    }, [selectedFilter, monitorsPreview, searchTerm]);

    const getStatusColor = (status: string) => {
        switch (status?.toUpperCase()) {
            case 'ACTIVE':
                return 'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20';
            case 'BLOCKED':
                return 'bg-red-500/10 text-red-400 border border-red-500/20';
            default:
                return 'bg-slate-500/10 text-slate-400 border border-slate-500/20';
        }
    };

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729]">
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Monitor Overview</h1>

                <div className="max-w-7xl mx-auto space-y-6">
                    {/* Main Container */}
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            {/* Filter and Search */}
                            <div className="flex flex-col sm:flex-row gap-4 mb-6">
                                <div className="relative">
                                    <button
                                        onClick={() => setFilterOpen(!filterOpen)}
                                        className="flex items-center gap-2 px-3 py-2 bg-[#0f1729] text-slate-300 rounded-lg text-sm border border-slate-700/50 hover:border-slate-600/50 transition-colors w-full sm:w-auto"
                                    >
                                        <Filter size={16} className="text-slate-400" />
                                        <span className="flex-1 text-left">{selectedFilter}</span>
                                        {filterOpen ?
                                            <ChevronUp size={16} className="text-slate-400" /> :
                                            <ChevronDown size={16} className="text-slate-400" />
                                        }
                                    </button>

                                    {filterOpen && (
                                        <div className="absolute mt-2 w-full min-w-[160px] py-1 bg-[#0f1729] rounded-lg border border-slate-700/50 shadow-xl z-10">
                                            {filters.map((filter) => (
                                                <button
                                                    key={filter}
                                                    onClick={() => {
                                                        setSelectedFilter(filter);
                                                        setFilterOpen(false);
                                                    }}
                                                    className="w-full px-3 py-2 text-sm text-slate-300 hover:bg-slate-700/50 text-left transition-colors"
                                                >
                                                    {filter}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="relative flex-1 sm:max-w-xs">
                                    <input
                                        type="text"
                                        placeholder="Search monitors..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <Search
                                        size={16}
                                        className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400"
                                    />
                                </div>
                            </div>

                            {/* Table */}
                            <div className="relative overflow-x-auto">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr className="border-b border-slate-700/50">
                                            {!developer && (
                                                <th className="py-3 px-4 text-sm font-medium text-slate-400">Monitor</th>
                                            )}
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Name</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Product</th>
                                            {developer && (
                                                <>
                                                    <th className="py-3 px-4 text-sm font-medium text-slate-400">Type</th>
                                                    <th className="py-3 px-4 text-sm font-medium text-slate-400">Status</th>
                                                </>
                                            )}
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredMonitors.map((monitor, i) => (
                                            <tr
                                                key={monitor.monitor_uuid}
                                                className="border-b border-slate-700/50 last:border-b-0 hover:bg-slate-700/20 transition-colors"
                                            >
                                                {!developer && (
                                                    <td className="py-3 px-4">
                                                        <span className="text-sm text-slate-300">{i + 1}</span>
                                                    </td>
                                                )}
                                                <td className="py-3 px-4">
                                                    <span className="text-sm text-slate-100 font-medium">{monitor.monitor_name}</span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <span className="text-sm text-slate-300">{monitor.product_name}</span>
                                                </td>
                                                {developer && monitor.status && (
                                                    <>
                                                        <td className="py-3 px-4">
                                                            <span className="text-sm text-slate-300">{monitor.monitor_type}</span>
                                                        </td>
                                                        <td className="py-3 px-4">
                                                            <span className={`inline-flex px-2 py-1 text-xs rounded-full ${getStatusColor(monitor.status)}`}>
                                                                {monitor.status}
                                                            </span>
                                                        </td>
                                                    </>
                                                )}
                                                <td className="py-3 px-4">
                                                    <button
                                                        onClick={() => handleEdit(monitor)}
                                                        className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                    >
                                                        <Edit2
                                                            size={16}
                                                            className="text-slate-400 group-hover:text-blue-400"
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        {filteredMonitors.length === 0 && (
                                            <tr>
                                                <td
                                                    colSpan={developer ? 5 : 4}
                                                    className="py-4 px-4 text-center text-sm text-slate-400"
                                                >
                                                    No monitors found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitorTable;