import React from 'react';

interface PendingProps {
    handleLogOut: () => void;
}

const Pending: React.FC<PendingProps> = ({ handleLogOut }) => (
    <div className="flex flex-col w-full min-h-screen bg-[#0f1729] items-center justify-center px-4">
        <div className="bg-[#1a2234] rounded-xl overflow-hidden shadow-xl max-w-md w-full p-8">
            <div className="space-y-4 text-center">
                <h1 className="text-2xl font-semibold text-slate-100">
                    Hey {localStorage.getItem("name")}!
                </h1>
                <p className="text-slate-400">
                    Your request is being processed and will take approximately 15 minutes.
                    You will receive a private message on Discord once it's finished!
                </p>
                <button
                    onClick={handleLogOut}
                    className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
                >
                    Log out
                </button>
            </div>
        </div>
    </div>
);

export default Pending;