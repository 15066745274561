import React from 'react';
import { ChevronRight } from 'lucide-react';
import { classNames } from '../../../utils/classNames';

interface MenuItemProps {
    icon: React.ElementType;
    path?: string;
    isActive?: boolean;
    label: string;
    isCollapsed: boolean;
    hasSubmenu?: boolean;
    isOpen?: boolean;
    onClick: () => void;
    isMobile?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
    icon: Icon,
    label,
    isActive,
    isCollapsed,
    hasSubmenu,
    isOpen,
    onClick,
    isMobile
}) => {
    return (
        <button
            onClick={onClick}
            className={classNames(
                "w-full flex items-center text-sm font-medium rounded-lg",
                "transition-colors duration-150 ease-in-out",
                isMobile ? "px-4 py-3" : "px-4 py-2",
                isActive
                    ? "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-white"
                    : "text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-white",
                isCollapsed && !isMobile ? "justify-center" : "justify-between"
            )}
        >
            <div className="flex items-center">
                <Icon
                    className={classNames(
                        "flex-shrink-0",
                        isCollapsed && !isMobile ? "h-5 w-5" : "h-5 w-5 mr-3"
                    )}
                />
                {(!isCollapsed || isMobile) && <span>{label}</span>}
            </div>
            {!isCollapsed && hasSubmenu && (
                <ChevronRight
                    className={classNames(
                        "h-4 w-4 transition-transform duration-200",
                        isOpen ? "transform rotate-90" : ""
                    )}
                />
            )}
        </button>
    );
};