import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import Settings from '../components/Sidebar/Settings';
import { BotData, Customer } from '../types';

interface SettingsPageProps {
    botData: BotData;
    customerData: Customer;
    handleLogout: () => void;
    handleBlur: () => void;
}

export const SettingsPage: React.FC<SettingsPageProps> = ({ botData, handleLogout, customerData, handleBlur }) => {
    const navigate = useNavigate();



    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }

    return (
        <div>
            <Settings botData={botData} customerData={customerData} handleLogOut={handleLogout} />
        </div>
    );
};