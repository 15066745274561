import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Save, Trash2, Edit2, XCircle, Plus, Search, ChevronDown, Check, X } from 'lucide-react';
import ReactDOM from 'react-dom';
import { API_URL } from '../../../constants';
import { Redirect } from '../../../types';


interface MonitorRedirectProps {
    handleBlur: () => void;
}

const MonitorRedirect: React.FC<MonitorRedirectProps> = ({ handleBlur }) => {
    const [redirects, setRedirects] = useState<Redirect[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [editingRedirect, setEditingRedirect] = useState<Redirect | null>(null);
    const [newRedirect, setNewRedirect] = useState<Redirect>({
        id: -1,
        webhook: "",
        keyword: "",
        type: "Name",
        active: true
    });

    useEffect(() => {
        fetchRedirectData();
    }, []);

    useEffect(() => {
        if (showPopup) {
            handleBlur()
        }
    }, [showPopup]);

    const fetchRedirectData = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/redirects`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token') || '',
                }
            });
            if (response.status === 200) {
                setRedirects(response.data.data.redirects);
            }
        } catch (error) {
            console.error('Error fetching redirect data:', error);
        }
    };

    const saveRedirect = async (redirect: Redirect) => {
        try {
            const response = await axios.put(`${API_URL}/v1/redirects`, {
                redirect: redirect,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Redirect updated successfully');
                fetchRedirectData();
            }
        } catch (error) {
            console.error('Failed to update redirect', error);
        }
    };

    const deleteRedirect = async (redirectId: number) => {
        try {
            const response = await axios.delete(`${API_URL}/v1/redirects`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                },
                data: { id: redirectId }
            });

            if (response.status === 200) {
                console.log('Redirect deleted successfully');
                setRedirects(prev => prev.filter(r => r.id !== redirectId));
            }
        } catch (error) {
            console.error('Failed to delete redirect', error);
        }
    };

    const createNewRedirect = async () => {
        try {
            const response = await axios.post(`${API_URL}/v1/redirects`, {
                redirect: newRedirect,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            if (response.status === 200) {
                console.log('Redirect created successfully');
                handleBlur();
                fetchRedirectData();
                setNewRedirect({
                    id: -1,
                    webhook: "",
                    keyword: "",
                    type: "Name",
                    active: true
                });
            }
        } catch (error) {
            console.error('Failed to create redirect', error);
        }
    };

    const toggleActive = (redirect: Redirect) => {
        const updatedRedirect = { ...redirect, active: !redirect.active };
        setRedirects(prevRedirects =>
            prevRedirects.map(r => r.id === redirect.id ? updatedRedirect : r)
        );
        saveRedirect(updatedRedirect);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (editingRedirect) {
            setEditingRedirect({
                ...editingRedirect,
                [name]: value
            });
        } else {
            setNewRedirect(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const openEditDialog = (redirect: Redirect) => {
        setEditingRedirect(redirect);
        setShowPopup(true);
    };

    const filteredRedirects = redirects.filter(redirect =>
        redirect.keyword.toLowerCase().includes(searchTerm.toLowerCase()) ||
        redirect.webhook.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const redirectTypes = ["Name", "SKU"];

    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729]">
            <div className="w-full px-4 sm:px-8 py-6">
                <h1 className="text-4xl text-white font-bold mb-6">Monitor Redirects</h1>

                <div className="max-w-5xl mx-auto space-y-6">
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden">
                        <div className="p-4 sm:p-6">
                            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search redirects..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full sm:w-64 bg-[#0f1729] rounded-lg pl-9 pr-4 py-2 text-sm text-slate-100 focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400" size={16} />
                                </div>

                                <button
                                    onClick={() => setShowPopup(true)}
                                    className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    <Plus size={16} />
                                    <span>Create Redirect</span>
                                </button>
                            </div>

                            <div className="relative overflow-x-auto">
                                <table className="w-full text-left border-collapse">
                                    <thead>
                                        <tr className="border-b border-slate-700/50">
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Keyword</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400">Webhook</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400 hidden sm:table-cell">Type</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center">Status</th>
                                            <th className="py-3 px-4 text-sm font-medium text-slate-400 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRedirects.map((redirect, index) => (
                                            <tr
                                                key={redirect.id}
                                                className={`border-b border-slate-700/50 ${index === filteredRedirects.length - 1 ? 'border-b-0' : ''
                                                    }`}
                                            >
                                                <td className="py-3 px-4">
                                                    <span className="text-slate-100 font-medium">{redirect.keyword}</span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <span className="text-slate-300 break-all">{redirect.webhook}</span>
                                                </td>
                                                <td className="py-3 px-4 hidden sm:table-cell">
                                                    <span className="text-slate-300">{redirect.type}</span>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <div className="flex justify-center">
                                                        <span className={`px-2 py-1 text-xs rounded-full ${redirect.active
                                                            ? 'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20'
                                                            : 'bg-red-500/10 text-red-400 border border-red-500/20'
                                                            }`}>
                                                            {redirect.active ? 'Active' : 'Inactive'}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-4">
                                                    <div className="flex items-center justify-center gap-2">
                                                        <button
                                                            onClick={() => toggleActive(redirect)}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            {redirect.active ? (
                                                                <X size={16} className="text-slate-400 group-hover:text-red-400" />
                                                            ) : (
                                                                <Check size={16} className="text-slate-400 group-hover:text-emerald-400" />
                                                            )}
                                                        </button>
                                                        <button
                                                            onClick={() => openEditDialog(redirect)}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Edit2 size={16} className="text-slate-400 group-hover:text-yellow-400" />
                                                        </button>
                                                        <button
                                                            onClick={() => deleteRedirect(redirect.id)}
                                                            className="p-2 hover:bg-slate-700/50 rounded-lg transition-colors group"
                                                        >
                                                            <Trash2 size={16} className="text-slate-400 group-hover:text-red-400" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && ReactDOM.createPortal(
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-[#1a2234] rounded-xl shadow-xl w-full max-w-md mx-4">
                        <div className="p-6">
                            <h2 className="text-xl font-semibold text-slate-100 mb-6">
                                {editingRedirect ? 'Edit Redirect' : 'Create Redirect'}
                            </h2>

                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Keyword</label>
                                    <input
                                        name="keyword"
                                        type="text"
                                        defaultValue={editingRedirect ? editingRedirect.keyword : newRedirect.keyword}
                                        onChange={handleInputChange}
                                        placeholder="Enter keyword"
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Webhook URL</label>
                                    <input
                                        name="webhook"
                                        type="text"
                                        defaultValue={editingRedirect ? editingRedirect.webhook : newRedirect.webhook}
                                        onChange={handleInputChange}
                                        placeholder="Enter webhook URL"
                                        className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm text-slate-400 mb-1">Type</label>
                                    <div className="relative">
                                        <select
                                            name="type"
                                            defaultValue={editingRedirect ? editingRedirect.type : newRedirect.type}
                                            onChange={handleInputChange}
                                            className="w-full bg-[#0f1729] rounded-lg px-3 py-2 text-slate-100 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 border border-slate-700/50 appearance-none"
                                        >
                                            {redirectTypes.map((type) => (
                                                <option key={type} value={type}>{type}</option>
                                            ))}
                                        </select>
                                        <ChevronDown
                                            size={16}
                                            className="absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 pointer-events-none"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-3">
                                <button
                                    onClick={() => {
                                        setShowPopup(false);
                                        handleBlur();
                                        setEditingRedirect(null);
                                    }}
                                    className="px-4 py-2 text-slate-300 hover:text-slate-100 text-sm transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        if (editingRedirect) {
                                            saveRedirect(editingRedirect);
                                        } else {
                                            createNewRedirect();
                                        }
                                        setShowPopup(false);
                                        handleBlur();
                                        setEditingRedirect(null);
                                    }}
                                    className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                                >
                                    {editingRedirect ? 'Save Changes' : 'Create Redirect'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </div>
    );
};

export default MonitorRedirect;