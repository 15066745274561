import { useState, useEffect } from 'react';
import axios from 'axios';
import { User, BotData, Customer } from '../types';
import { API_URL, DASHBOARDURL } from '../constants';

interface AuthState {
    isLoading: boolean;
    developer: boolean;
    isAuthorized: boolean;
    user: User | null;
    botData: BotData | null;
    customerData: Customer | null;
    handleLogout: () => void;
    paid: boolean;
    hasCustomerProfile: boolean;
    profilePending: boolean;
    setHasCustomerProfile: (state: boolean) => void;
    gotData: boolean;
}

export const useAuth = (): AuthState => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [developer, setDeveloper] = useState(false);
    const [botData, setBotData] = useState<BotData | null>(null);
    const [customerData, setCustomerData] = useState<Customer | null>(null);
    const [paid, setPaid] = useState(false);
    const [hasCustomerProfile, setHasCustomerProfile] = useState(false);
    const [profilePending, setProfilePending] = useState(false);
    const [gotData, setGotData] = useState(false);


    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setIsAuthorized(false);
                    setIsLoading(false);
                    return;
                }

                const response = await axios.get(`${API_URL}/v1/customer/info`, {
                    headers: {
                        Authorization: token
                    }
                });
                setGotData(true);
                if (response.status === 200) {
                    const { data } = response.data;

                    setPaid(data.paid);

                    if (Object.keys(data.customer_data).length > 0) {
                        setHasCustomerProfile(true);
                    }
                    setProfilePending(data.bot_pending)

                    setUser({
                        name: localStorage.getItem('name') || '',
                        image: localStorage.getItem('image') || ''
                    });

                    if ((data.has_bot || Object.keys(data.customer_data).length > 0) && !data.bot_pending) {
                        setBotData(data.bot_data);

                        setCustomerData(data.customer_data);
                        setIsAuthorized(true);
                    } else {
                        setIsAuthorized(false);
                    }

                    // check developer
                    if (response.data.data && response.data.data.developer) {
                        const developerValue = response.data.data.developer;
                        setDeveloper(developerValue);
                    }
                }

            } catch (error) {
                console.error('Auth initialization failed:', error);
                setIsAuthorized(false);
            } finally {
                setIsLoading(false);
            }
        };

        initializeAuth();
    }, []);

    const handleLogout = async () => {
        try {
            console.log("aa-")
            const token = localStorage.getItem('token');
            await axios.post(`${API_URL}/v1/logout`, null, {
                headers: {
                    Authorization: token || ''
                }
            });
        } catch (error) {
            console.error('Logout failed:', error);
        } finally {
            localStorage.removeItem('token');
            localStorage.removeItem('name');
            localStorage.removeItem('image');
            window.location.href = DASHBOARDURL;
        }
    };

    return {
        isLoading,
        isAuthorized,
        user,
        botData,
        customerData,
        handleLogout,
        developer,
        paid,
        hasCustomerProfile,
        profilePending,
        setHasCustomerProfile,
        gotData
    };
};