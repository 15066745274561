import React, { useState, useEffect, useRef } from "react";
import { toast, Id } from 'react-toastify';
import { ArrowLeft, ChevronDown } from 'lucide-react';
import WebhookSection from './WebhookSection';
import MonitorDetailsSection from './MonitorDetailsSection';
import MonitoredLinksSection from './MonitoredLinksSection';
import { Monitor, MonitorSettingsProps, StatusCode } from '../../../../types';
import axios from "axios";
import { API_URL } from "../../../../constants";
import { CustomToastContainer } from "../../../common/CustomToastContainer";
import StatusCodeGraph from "./StatusCodeGraph";
import { useLoading } from "../../../../contexts/LoadingContext";

const MonitorSettings: React.FC<MonitorSettingsProps> = ({
    currentMonitor,
    handleBackToTable,
    setCurrentMonitor,
    handleLogOut,
    tempMonitor,
    setTempMonitor,
    handleBlur,
    developer,
    customerData
}) => {
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [hasChanged, setHasChanged] = useState(false);
    const toastIdRef = useRef<Id | null>(null);
    const [showGraph, setShowGraph] = useState<boolean>(false);
    const [statusCodes, setStatusCodes] = useState<StatusCode[]>([]);

    const { setIsLoading } = useLoading();

    const showToast = (message: string, type: "info" | "success" | "error") => {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
            toast.update(toastIdRef.current, {
                render: message,
                type: type,
                isLoading: type === "info",
                autoClose: type !== "info" ? 3000 : false,
            });
        } else {
            toastIdRef.current = type === "info"
                ? toast.loading(message)
                : toast(message, {
                    type: type,
                    isLoading: false,
                    autoClose: 3000
                });
        }
    };

    useEffect(() => {
        if (hasChanged) {
            updateWebhooks();
        }
    }, [currentMonitor]);

    const updateWebhooks = async () => {
        showToast("Updating webhooks..", "info")

        if (currentMonitor) {
            try {
                const webhooksData = currentMonitor.webhooks.map((webhook) => ({
                    region: webhook.region,
                    webhook_url: webhook.webhook_url,
                }));

                const response = await axios.post(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/webhooks`, {
                    webhooks: webhooksData,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                });

                if (response.status === 200) {
                    console.log('Webhooks updated successfully');
                    showToast("Webhooks updated successfully", "success")
                    setBtnDisabled(true);
                }
            } catch (error) {
                console.error('Failed to update webhooks', error);
                showToast("Failed to update webhooks", "error")
                handleLogOut();
            } finally {
                setIsLoading(false);
            }
        }
    };
    const getStatusCodes = async () => {
        try {
            setIsLoading(true);
            if (currentMonitor) {


                const response = await axios.get(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/graph`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token")
                    }
                });
                if (response.status === 200) {
                    setStatusCodes(response.data.data)
                }
            }
        } catch (error) {
            handleLogOut();
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleGraphButtonClick = () => {


        if (!showGraph) {
            // get graph data
            getStatusCodes()
        }
        setShowGraph(!showGraph)

    }



    return (
        <div className="flex flex-col w-full min-h-screen bg-[#0f1729] scrollable">
            <CustomToastContainer limit={1} />

            <div className="w-full px-4 sm:px-8 py-6">
                {developer && (
                    <div className="mb-6">
                        <button
                            onClick={handleGraphButtonClick}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition-colors"
                        >
                            <ChevronDown size={16} className={`transform transition-transform ${showGraph ? 'rotate-180' : ''}`} />
                            <span>{showGraph ? "Hide" : "Show"} Graph</span>
                        </button>
                    </div>
                )}

                {showGraph ? (
                    <div className="bg-[#1a2234] rounded-xl overflow-hidden h-[700px] mb-6">
                        <StatusCodeGraph data={statusCodes} />
                    </div>
                ) : currentMonitor && (
                    <div className="max-w-5xl mx-auto space-y-6">
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                            <div>
                                <h2 className="text-2xl font-semibold text-slate-100">
                                    {currentMonitor.data.monitor_name}
                                </h2>
                                <div className="mt-2">
                                    <span className="text-sm text-slate-400">Category: </span>
                                    <span className="text-sm text-slate-300">{currentMonitor.data.product_name}</span>
                                </div>
                            </div>

                            <button
                                onClick={handleBackToTable}
                                className="flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors text-sm"
                            >
                                <ArrowLeft size={16} />
                                <span>Back to Monitor List</span>
                            </button>
                        </div>

                        {developer && currentMonitor.monitorSettings && (
                            <MonitorDetailsSection
                                setIsLoading={setIsLoading}
                                currentMonitor={currentMonitor} setCurrentMonitor={setCurrentMonitor} />
                        )}

                        {currentMonitor.webhooks.length > 0 && (
                            <WebhookSection
                                currentMonitor={currentMonitor}
                                setCurrentMonitor={setCurrentMonitor}
                                setHasChanged={setHasChanged}
                            />
                        )}

                        <MonitoredLinksSection
                            currentMonitor={currentMonitor}
                            setCurrentMonitor={setCurrentMonitor}
                            tempMonitor={tempMonitor}
                            setTempMonitor={setTempMonitor}
                            showToast={showToast}
                            developer={developer}
                            customerData={customerData}
                            handleBlur={handleBlur}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default MonitorSettings;
