import React from 'react';
import { Barcode, Globe, Link } from 'lucide-react';

interface ExampleInputsProps {
    inputs: string[];
}

const determineInputType = (input: string): 'url' | 'ean' | 'sku' | 'unknown' => {
    if (input.startsWith('http://') || input.startsWith('https://')) {
        return 'url';
    } else if (/^\d{13}$/.test(input)) {
        return 'ean';
    } else if (/^[A-Z0-9-]{6,12}$/.test(input)) {
        return 'sku';
    } else {
        return 'unknown';
    }
};

const InputExample: React.FC<{ value: string }> = ({ value }) => {
    const type = determineInputType(value);

    const getIconConfig = (type: 'url' | 'ean' | 'sku' | 'unknown') => {
        switch (type) {
            case 'sku':
                return {
                    Icon: Barcode,
                    containerClass: 'bg-blue-500/10 text-blue-400 border border-blue-500/20',
                    iconClass: 'text-blue-400'
                };
            case 'ean':
                return {
                    Icon: Barcode,
                    containerClass: 'bg-emerald-500/10 text-emerald-400 border border-emerald-500/20',
                    iconClass: 'text-emerald-400'
                };
            case 'url':
                return {
                    Icon: Globe,
                    containerClass: 'bg-purple-500/10 text-purple-400 border border-purple-500/20',
                    iconClass: 'text-purple-400'
                };
            default:
                return {
                    Icon: Link,
                    containerClass: 'bg-slate-500/10 text-slate-400 border border-slate-500/20',
                    iconClass: 'text-slate-400'
                };
        }
    };

    const { Icon, containerClass, iconClass } = getIconConfig(type);

    return (
        <div className={`flex items-center rounded-lg p-2 ${containerClass}`}>
            <div className="flex items-center gap-3">
                <Icon size={16} className={iconClass} />
                <span className="text-sm font-medium">{value}</span>
            </div>
        </div>
    );
};

const ExampleInputs: React.FC<ExampleInputsProps> = ({ inputs }) => {
    if (!inputs.length) return null;

    return (
        <div className="bg-[#1a2234] rounded-xl overflow-hidden">
            <div className="p-4 sm:p-6">
                <div className="flex items-center gap-2 mb-4">
                    <h3 className="text-sm font-medium text-slate-300">Example Inputs</h3>
                    <span className="px-2 py-0.5 text-xs rounded-full bg-slate-500/10 text-slate-400 border border-slate-500/20">
                        {inputs.length}
                    </span>
                </div>

                <div className="space-y-2">
                    {inputs.map((input, index) => (
                        <InputExample key={index} value={input} />
                    ))}
                </div>

                {inputs.length === 0 && (
                    <p className="text-sm text-slate-400 text-center py-2">
                        No example inputs available
                    </p>
                )}
            </div>
        </div>
    );
};

export default ExampleInputs;