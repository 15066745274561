import React from 'react';
import { useNavigate } from 'react-router-dom';
import Monitors from '../../components/Sidebar/Monitor/MonitorMain';
import { BotData, Customer } from '../../types';

interface SettingsPageProps {
    botData: BotData;
    customerData: Customer;
    handleLogout: () => void;
    handleBlur: () => void;
    developer: boolean
}

export const MonitorsPage: React.FC<SettingsPageProps> = ({ botData, handleLogout, customerData, handleBlur, developer }) => {
    const navigate = useNavigate();

    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }

    return (
        <div>
            <Monitors handleLogOut={handleLogout} handleBlur={handleBlur} developer={developer} customerData={customerData} />
        </div>
    );
};