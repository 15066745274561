import React, { useState } from 'react';
import { AlertTriangle, ChevronUp, ChevronDown } from 'lucide-react';

const UpcomingRenewals = ({ customers }) => {
    const [isRenewalListExpanded, setIsRenewalListExpanded] = useState(true);

    return (
        <div className="w-full bg-slate-800/50 border border-slate-700/50 rounded-lg overflow-hidden">
            <button
                onClick={() => setIsRenewalListExpanded(!isRenewalListExpanded)}
                className="w-full px-3 py-2 sm:p-4 flex items-center justify-between text-left hover:bg-slate-700/20 transition-colors"
            >
                <div className="flex items-center gap-2 sm:gap-3">
                    <AlertTriangle size={20} className="text-yellow-500 sm:w-6 sm:h-6" />
                    <div className="min-w-0">
                        <h2 className="text-lg sm:text-xl font-semibold text-yellow-100 truncate">
                            Upcoming Renewals
                        </h2>
                        <p className="text-xs sm:text-sm text-slate-400">
                            {customers.length} customer{customers.length !== 1 ? 's' : ''} due for renewal in the next 7 days
                        </p>
                    </div>
                </div>
                <div className="flex items-center gap-1 sm:gap-2 ml-2">
                    <span className="text-xs sm:text-sm text-slate-400">
                        {isRenewalListExpanded ? 'Hide' : 'Show'}
                    </span>
                    {isRenewalListExpanded ? (
                        <ChevronUp size={16} className="text-slate-400 sm:w-5 sm:h-5" />
                    ) : (
                        <ChevronDown size={16} className="text-slate-400 sm:w-5 sm:h-5" />
                    )}
                </div>
            </button>

            {isRenewalListExpanded && (
                <div className="p-2 sm:p-4 pt-0">
                    <div className="grid gap-2 sm:gap-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                        {customers.map(customer => {
                            const now = new Date();
                            const renewalDate = new Date(customer.next_renewal);
                            const daysUntilRenewal = Math.ceil(
                                (renewalDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
                            );
                            const isOverdue = daysUntilRenewal < 0;

                            return (
                                <div
                                    key={customer.customer_id}
                                    className="flex items-center justify-between p-2 sm:p-3 rounded-lg bg-slate-700/30 border border-slate-600/30 hover:border-slate-600/50 transition-colors"
                                >
                                    <div className="flex items-center gap-2 sm:gap-3 min-w-0">
                                        <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-lg overflow-hidden ring-2 ring-slate-700/50 flex-shrink-0">
                                            <img
                                                src={customer.image_url}
                                                alt={customer.name}
                                                className="h-full w-full object-cover"
                                            />
                                        </div>
                                        <div className="min-w-0">
                                            <span className="font-medium text-sm sm:text-base text-slate-200 block truncate">
                                                {customer.name}
                                            </span>
                                            <div className="text-xs sm:text-sm text-slate-400">
                                                {new Date(customer.next_renewal).toLocaleDateString()}
                                            </div>
                                        </div>
                                    </div>
                                    {isOverdue ? (
                                        <span className="text-xs sm:text-sm px-2 py-1 rounded-full bg-red-500/10 text-red-400 border border-red-500/20 whitespace-nowrap ml-2">
                                            {Math.abs(daysUntilRenewal)}d overdue
                                        </span>
                                    ) : (
                                        <span className="text-xs sm:text-sm px-2 py-1 rounded-full bg-yellow-500/10 text-yellow-400 border border-yellow-500/20 whitespace-nowrap ml-2">
                                            {daysUntilRenewal}d left
                                        </span>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpcomingRenewals;