import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BotData, Customer } from '../../types';
import MonitorRedirect from '../../components/Sidebar/Monitor/MonitorRedirect';


interface SettingsPageProps {
    botData: BotData;
    customerData: Customer;
    handleLogout: () => void;
    handleBlur: () => void;
}

export const RedirectsPage: React.FC<SettingsPageProps> = ({ botData, handleLogout, customerData, handleBlur }) => {
    const navigate = useNavigate();

    // Early return for invalid data
    if (!botData || !customerData) {
        navigate('/');
        return null;
    }

    return (
        <div>
            <MonitorRedirect handleBlur={handleBlur} />
        </div>
    );
};